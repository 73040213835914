
import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import Pagination from '../../Config/Pagination';
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Link, useNavigate } from "react-router-dom";
import '../../Config/search.css';
import AddContact from "./AddContact";
import SetReminder from "./SetReminder";
import FollowUp from "./FollowUp";
import '../../Config/loader.css';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../../Config/loading.css';

export default function Leads() {
    const navigate = useNavigate();

    const [leadsData, setLeadsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [contactLeadOBJ, setContactLeadOBJ] = useState([]);
    const [remidnerLeadOBJ, setRemidnerLeadOBJ] = useState([]);
    const [followupLeadOBJ, setFollowupLeadOBJ] = useState([]);

    useEffect(() => {
        const userDetails = sessionStorage.getItem('userDetails');
        if (!userDetails) {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
                const response = await fetch(`${API_BASE_CRM_URL}GetLeads`);
                    if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
        
                if (data.Status) {
                    setLeadsData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setLeadsData([]); // Set an empty array to avoid errors
                }
            } catch (error) {
                    console.error("Error fetching data:", error);
            } finally {
                    setLoading(false);
            }
        };
    
        fetchData();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
    
        // Extract day, month, and year
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
    
        // Format as dd-mm-yyyy
        return `${day}-${month}-${year}`;
    };

    const showContactModal = (obj) => {
        setContactLeadOBJ(obj);
    };

    const showReminderModal = (obj) => {
        setRemidnerLeadOBJ(obj);
    };

    const showFollowupModal = (obj) => {
        setFollowupLeadOBJ(obj);
    };

    const itemsPerPage = 10;

    const filteredItems = leadsData.filter(item => 
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.phonenumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.source_name && item.source_name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    
    // Calculate indices for slicing data
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleExcelDownload = () => {
        if (leadsData.length === 0) {
            return; // Don't download if there is no data
        }

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert your data to worksheet format
        const wsData = leadsData.map((item) => ({
            'Name': item.name,
            'Company': item.company,
            'Phone Number': item.phonenumber,
            'Date Created': formatDate(item.datecreated),
            'Last Contact': formatDate(item.lastcontact),
            'Source': item.SourceName,
        }));

        const ws = XLSX.utils.json_to_sheet(wsData);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'LeadsData');

        // Save the workbook to a file
        XLSX.writeFile(wb, 'LeadsData.xlsx');
    };

    const handlePDFDownload = () => {
        const doc = new jsPDF();

        // Set properties for PDF
        doc.setProperties({
            title: 'Leads Data',
            author: 'Your Name',
            creator: 'Your Application'
        });

        // Add data to PDF
        const tableData = []; // Your data array here

        // Add a header row
        const headers = ['Name', 'Company', 'Phone Number', 'Created Date', 'Last Conatct', 'Source'];
        tableData.push(headers);

        // Add rows
        leadsData.forEach(item => {
            const row = [];
            row.push(item.name, item.company, item.phonenumber, formatDate(item.datecreated), item.lastcontact, item.SourceName);
            tableData.push(row);
        });

        // Generate table
        doc.autoTable({
            head: [headers],
            body: tableData.slice(1),
        });

        // Save the PDF
        doc.save('LeadsData.pdf');
    };
    

    return (
        <Base>
            
            <div className="page-header">
    <div className="d-flex justify-content-between flex-wrap">
        <div className="page-title">
            <h4>Leads</h4>
            <h6>Manage your leads</h6>
        </div>
      
    </div>
    <div className="d-flex flex-wrap align-items-center mt-3">
        <div className="flex-fill d-md-flex justify-content-between align-items-center">
            <div className="input-group mb-2 mb-md-0">
                <input 
                    type="text" 
                    name="search" 
                    placeholder="Search..." 
                    className="form-control" 
                    value={searchTerm} 
                    onChange={(e) => setSearchTerm(e.target.value)} 
                />
                <button className="btn btn-primary" type="button">
                    <i className="fas fa-search"></i>
                </button>
            </div>
        </div>
        <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
            <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf" onClick={handlePDFDownload}>
                    <img src="assets/img/icons/pdf.svg" alt="PDF" />
                </a>
            </li>
            <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel" onClick={handleExcelDownload}>
                    <img src="assets/img/icons/excel.svg" alt="Excel" />
                </a>
            </li>
            {/* <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print">
                    <i className="fa-solid fa-print fs-5"></i>
                </a>
            </li> */}
            <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh">
                    <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
                </a>
            </li>
            <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Collapse" id="collapse-header">
                    <i className="fa-solid fa-chevron-up fs-6"></i>
                </a>
            </li>
            <li className="ms-2 ms-md-3">
            <Link className="btn btn-added" to="/add-lead">
                <i className="fa-solid fa-plus"></i>
            </Link>
            </li>
        </ul>
    </div>
</div>




            <div className="card table-list-card">
                <div className="card-body">

                    <div className="table-responsive product-list d-none d-lg-block d-md-block" style={{ minHeight: '24rem' }}>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="no-sort">#</th>
                                    <th className="no-sort">Action</th>
                                    <th>Name</th>
                                    <th>Company</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Source</th>
                                </tr>
                            </thead>
                            <tbody>

                                {loading ? 
                                <td colSpan={12} className="text-center">
                                    <p className="text-center">
                                        <div class="containers m-auto">
                                            <div class="dot"></div>
                                            <div class="dot"></div>
                                            <div class="dot"></div>
                                            <div class="dot"></div>
                                            <div class="dot"></div>
                                            <div class="dot"></div>
                                        </div>
                                    </p>
                                </td>
                                :
                                currentItems && currentItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                           {index + 1}
                                        </td>
                                        <td className="text-start">
                                            <div className="dropdown">
                                                <a className="action-set" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                </a>
                                                <div className="dropdown-menu">
                                                    <Link to={`/view-lead/${item.id}`} className="dropdown-item" >
                                                        <i className="fa-regular fa-eye me-2"></i>View
                                                    </Link>
                                                    <Link  className="dropdown-item" to={`/edit-lead/${item.id}`}>
                                                        <i className="fa-regular fa-pen-to-square text-info me-2"></i>Edit 
                                                    </Link>
                                                    <a 
                                                        className="dropdown-item" 
                                                        data-bs-toggle="offcanvas" 
                                                        data-bs-target="#offcanvasContact" 
                                                        aria-controls="offcanvasContact"
                                                        onClick={() => showContactModal(item)}
                                                    >
                                                        <i className="fa-regular fa-address-book me-2 text-success"></i>Add Contact
                                                    </a>
                                                    <a 
                                                        className="dropdown-item" 
                                                        data-bs-toggle="offcanvas" 
                                                        data-bs-target="#offcanvasReminder" 
                                                        aria-controls="offcanvasReminder"
                                                        onClick={() => showReminderModal(item)}
                                                    >
                                                        <i className="fa-solid fa-business-time me-2 text-info"></i>Set Reminder
                                                    </a>
                                                    <a 
                                                        className="dropdown-item" 
                                                        data-bs-toggle="offcanvas" 
                                                        data-bs-target="#offcanvasFollowup" 
                                                        aria-controls="offcanvasFollowup"
                                                        onClick={() => showFollowupModal(item)}
                                                    >
                                                        <i className="fa-solid fa-reply-all me-2"></i>Followup
                                                    </a>
                                                    {/* <a className="dropdown-item confirm-text" href="javascript:void(0);">
                                                        <i className="fa-regular fa-trash-can text-danger me-2"></i>Delete
                                                    </a> */}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <Link to={`/view-lead/${item.id}`}>{item.name}</Link>
                                        </td>
                                        <td>{item.company}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phonenumber}</td>
                                        <td>{item.SourceName ? item.SourceName : 'N/A'}</td>
                                        {/* <td>{item.lastcontact ? formatDate(item.lastcontact) : 'N/A'}</td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={leadsData.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    </div>

                    {/* Mobile View */}
                    <div className="row d-block d-md-none d-lg-none" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        {loading ? <p className=" m-auto fs-6">Loading...</p> :
                            currentItems && currentItems.map((item, index) => (
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6" key={index}>
                                <div className="employee-grid-profile">
                                    <div className="profile-head">
                                        <label className="checkboxs">
                                            <input type="checkbox"/>
                                            <span className="checkmarks"></span>
                                        </label>
                                        <div className="profile-head-action">
                                            <span className="badge badge-linesuccess text-center w-auto me-1">Active</span>
                                            <div className="dropdown profile-action">
                                                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 27 24" fill="none"
                                                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                        className="feather feather-more-vertical feather-user">
                                                        <circle cx="12" cy="12" r="1"></circle>
                                                        <circle cx="12" cy="5" r="1"></circle>
                                                        <circle cx="12" cy="19" r="1"></circle>
                                                    </svg>
                                                </a>
                                                <ul className="dropdown-menu" style={{ width: '8.5rem' }}>
                                                    <Link to={`/view-lead/${item.id}`} className="dropdown-item" >
                                                        <i className="fa-regular fa-eye me-2"></i>View
                                                    </Link>
                                                    <Link  className="dropdown-item" to={`/edit-lead/${item.id}`}>
                                                        <i className="fa-regular fa-pen-to-square text-info me-2"></i>Edit 
                                                    </Link>
                                                    <a 
                                                        className="dropdown-item" 
                                                        data-bs-toggle="modal" 
                                                        data-bs-target="#add-lead-contact"
                                                        onClick={() => showContactModal(item)}
                                                    >
                                                        <i className="fa-regular fa-address-book me-2 text-success"></i>Add Contact
                                                    </a>
                                                    <a 
                                                        className="dropdown-item" 
                                                        data-bs-toggle="modal" 
                                                        data-bs-target="#set-reminder"
                                                        onClick={() => showReminderModal(item)}
                                                    >
                                                        <i className="fa-solid fa-business-time me-2 text-info"></i>Set Reminder
                                                    </a>
                                                    <a 
                                                        className="dropdown-item" 
                                                        data-bs-toggle="modal" 
                                                        data-bs-target="#followup-lead"
                                                        onClick={() => showFollowupModal(item)}
                                                    >
                                                        <i className="fa-solid fa-reply-all me-2"></i>Followup
                                                    </a>
                                                  
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div>
                                        <table className="table">
                                            <tbody>
                                                <tr className="text-start">
                                                    <td>{item.name}</td>
                                                    <td>{item.title}</td>
                                                </tr>
                                                <tr className="text-start">
                                                    <td>{item.city}</td>
                                                    <td>{item.company}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <ul className="department">
                                        <li>
                                            Last Contacted
                                            <span>{formatDate(item.lastcontact)}</span>
                                        </li>
                                        <li>
                                            Created
                                            <span>{formatDate(item.dateadded)}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                         ))}
                    </div>
                </div>
            </div>

            <AddContact leadOBJ={contactLeadOBJ}/>
            <SetReminder leadOBJ={remidnerLeadOBJ}/>
            <FollowUp leadOBJ={followupLeadOBJ}/>

        </Base>
    )
}