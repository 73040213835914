
// Node apis ecotec server
const API_BASE_CRM_URL = 'https://crmxtnapi.azurewebsites.net/';

// Prefix apis
// const API_BASE_CRM_URL = 'https://mycrm.ibizaccounts.com/api/'

// Ecotec local ecotec
// const API_BASE_CRM_URL = 'http://192.168.1.19:3002/';

// const BASE_API_KEY = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiYXBwc0BwZXJlbm5pYWxjb2RlLmluIiwibmFtZSI6IkRldmVsb3BlciIsIkFQSV9USU1FIjoxNzA4NjkzMzIwfQ.PoXlVNphTxBluptxrYSko6ENBnyO6Vgw87anIGxv0Y4"

export { API_BASE_CRM_URL };
