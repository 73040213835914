import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Base from '../../Config/Base';
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import AddStatus from './AddStatus';
import AddSource from './AddSource';
import '../../Config/swal.css';


export default function EditLead() {
    const { id } = useParams(); 
    const navigate = useNavigate();

    const [leadsData, setLeadsData] = useState({});
    const [leadsStatusData, setLeadsStatusData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [leadSourceData, setLeadSourceData] = useState([]);
    const [selectedSourceId, setSelectedSourceId] = useState('');
    const [selectedStatusId, setSelectedStatusId] = useState('');
    const [formValues, setFormValues] = useState({
        Name: '',
        Title: '',
        Company: '',
        Description: '',
        Country: '',
        City: '',
        Zip: '',
        State: '',
        Address: '',
        Source: '',
        Status: '',
        Email: '',
        Website: '',
        PhoneNumber: '',
    });

    useEffect(() => {
        const userDetails = sessionStorage.getItem('userDetails');
        if (!userDetails) {
            navigate('/');
        }
    }, [navigate]);

    const sourceOptions =  leadSourceData && leadSourceData.map((item) => ({
        value: item.SourceId,
        label: item.SourceName,
    }));

    const statusOptions =  leadsStatusData && leadsStatusData.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    const handleSourceSelectChange = (selectedOption) => {
        setSelectedSourceId(selectedOption);
        setFormValues(prevData => ({
            ...prevData,
            Source: selectedOption
        }));
    };

    const handleStatusSelectChange = (selectedOption) => {
        setSelectedStatusId(selectedOption);
        setFormValues(prevData => ({
            ...prevData,
            Status: selectedOption
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
                const response = await fetch(`${API_BASE_CRM_URL}GetLeadDetails?LeadId=${id}`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                console.log(data.ResultData[0],'ssssssssssssssssssssssssss')
        
                if (data.Status) {
                    setLeadsData(data.ResultData[0]);
                    setFormValues({
                        Name: data.ResultData[0].name || '',
                        Title: data.ResultData[0].title || '',
                        Company: data.ResultData[0].company || '',
                        Description: data.ResultData[0].description || '',
                        Country: data.ResultData[0].country || '',
                        City: data.ResultData[0].city || '',
                        Zip: data.ResultData[0].zip || '',
                        DefaultLanguage: data.ResultData[0].default_language || '',
                        State: data.ResultData[0].state || '',
                        Address: data.ResultData[0].address || '',
                        Source: data.ResultData[0].source || '',
                        LastContact: formatDate(data.ResultData[0].lastcontact) || '',
                        AddedFrom: data.ResultData[0].addedfrom || '',
                        Status: data.ResultData[0].status || '',
                        Email: data.ResultData[0].email || '',
                        Website: data.ResultData[0].website || '',
                        PhoneNumber: data.ResultData[0].phonenumber || ''
                    });

                    setSelectedSourceId(data.ResultData[0].source || '');
                    setSelectedStatusId(data.ResultData[0].status || '');
                } else {
                    console.error("Fetched data is not an array:", data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
                const response = await fetch(`${API_BASE_CRM_URL}GetLeadStatus`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
        
                if (data.Status) {
                    setLeadsStatusData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
                const response = await fetch(`${API_BASE_CRM_URL}GetLeadSources`);
                    if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
        
                if (data.Status) {
                    setLeadSourceData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setLeadSourceData([]); // Set an empty array to avoid errors
                }
            } catch (error) {
                    console.error("Error fetching data:", error);
            } finally {
                    setLoading(false);
            }
        };
    
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        formValues.Status = selectedStatusId;
        console.log(formValues,'data sending to api');

        try {
            const response = await fetch(`${API_BASE_CRM_URL}UpdateLead`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Id: id,
                    ...formValues
                }),
            });

            const result = await response.json();

            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Lead Updated Successfully.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        navigate('/leads');
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to Request Submit.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error updating lead.');
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (isoString) => {
        if (!isoString) return '';
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    

    return (
        <Base>
            <div className="page-heade mb-3">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="page-title">
                            <h4>Edit Lead</h4>
                            <p className="fw-semibold">Modify the details of the lead below</p>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-md-end justify-content-start">
                        <Link to='/leads'>
                            <button className="btn btn-primary" style={{ borderRadius: '10px' }}>
                                <i className="fa-solid fa-arrow-left me-2"></i> Go to List
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
    
            <div className="col-xxl-12 col-xl-12">
                <div className="card">
                    <div className="card-body" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        <form onSubmit={handleSubmit}>
                            <div className='row m-3'>
                                <h5 className='mb-2'>Organization Infromation:</h5>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Name</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='Company'
                                        value={formValues.Company}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2 d-flex flex-column'>
                                    <label className='form-label'>
                                        Source<span className='text-danger'>*</span>
                                    </label>
                                    <div className='d-flex'>
                                        <Select
                                            showSearch
                                            placeholder="Choose source"
                                            value={selectedSourceId || null}
                                            onChange={handleSourceSelectChange}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={sourceOptions}
                                            style={{ flexGrow: 1, height: '2.4rem' }}
                                        />
                                        <button 
                                            type='button'
                                            className='btn btn-dark text-light ms-2' 
                                            data-bs-toggle="modal" 
                                            data-bs-target="#add-source"
                                        >
                                            <i className='fa fa-plus'></i>
                                        </button>
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2 d-flex flex-column'>
                                    <label className='form-label'>
                                        Status<span className='text-danger'>*</span>
                                    </label>
                                    <div className='d-flex'>
                                        <Select
                                            showSearch
                                            placeholder="Choose status"
                                            value={selectedStatusId || null}
                                            onChange={handleStatusSelectChange}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={statusOptions}
                                            style={{ flexGrow: 1, height: '2.4rem' }}
                                        />
                                        <button 
                                            type='button'
                                            className='btn btn-dark text-light ms-2' 
                                            data-bs-toggle="modal" 
                                            data-bs-target="#add-status"
                                        >
                                            <i className='fa fa-plus'></i>
                                        </button>
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Address</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='Address'
                                        value={formValues.Address}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>City</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='City'
                                        value={formValues.City}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Zipcode</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='Zip'
                                        value={formValues.Zip}
                                        onChange={handleChange}
                                    />
                                </div>
                                
                                <h5 className='mb-2 mt-3'>Contact Person:</h5>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Name</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='Name'
                                        value={formValues.Name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Title</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='Title'
                                        value={formValues.Title}
                                        onChange={handleChange}
                                    />
                                </div>
                                
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Phone Number</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='PhoneNumber'
                                        value={formValues.PhoneNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                                {/* <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>State</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='State'
                                        value={formValues.State}
                                        onChange={handleChange}
                                    />
                                </div> */}
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Email</label>
                                    <input
                                        className='form-control'
                                        type='email'
                                        name='Email'
                                        value={formValues.Email}
                                        onChange={handleChange}
                                    />
                                </div>
                                
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Website</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='Website'
                                        value={formValues.Website}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='row m-3 m-auto text-center'>
                                <button type="submit" className="btn btn-primary col-2 m-auto text-center">
                                    {loading ? 'Submitting...' : 'Save Changes'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <AddStatus />
            <AddSource />
        </Base>
    );
}
