import React, { useState } from "react";


const HealthPolicyForm = () => {
  const [formData, setFormData] = useState({
    personal: {
      name: "",
      mailId: "",
      mobileNo: "",
      alternateNo: "",
      address: "",
      dob: "",
      panNo: "",
      aadharNo: "",
      height: "",
      weight: "",
      preExistingDisease: "",
    },
    spouse: {
      name: "",
      dob: "",
      height: "",
      weight: "",
      preExistingDisease: "",
    },
    children: [
      {
        name: "",
        gender: "",
        dob: "",
        height: "",
        weight: "",
        preExistingDisease: "",
      },
      {
        name: "",
        gender: "",
        dob: "",
        height: "",
        weight: "",
        preExistingDisease: "",
      },
    ],
    healthInsurance: "",
    panCardCopy: null,
    aadharCard: null,
  });

  const handleChange = (e, section, index) => {
    const { name, value } = e.target;
    if (section === "children") {
      const newChildren = [...formData.children];
      newChildren[index][name] = value;
      setFormData((prevState) => ({
        ...prevState,
        children: newChildren,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [name]: value,
        },
      }));
    }
  };

  const handleFileChange = (e, fieldName) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: e.target.files[0],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Add your form submission logic here
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Health Policy Form</h2>
      <form onSubmit={handleSubmit}>
        
        {/* Personal Details */}
        <div className="card mb-4">
          <div className="card-header">Personal Details</div>
          <div className="card-body">
            {Object.keys(formData.personal).map((key) => (
              <div className="col-md-4 mb-2" key={key}>
                <label className="form-label">
                  {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1")}
                  {key !== "alternateNo" && key !== "preExistingDisease" ? <span className="text-danger">*</span> : null}
                </label>
                <input
                  type={key === "dob" ? "date" : key.includes("No") || key === "height" || key === "weight" ? "number" : "text"}
                  className="form-control"
                  name={key}
                  value={formData.personal[key]}
                  onChange={(e) => handleChange(e, "personal")}
                  placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
                  required={key !== "alternateNo" && key !== "preExistingDisease"}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Spouse Details */}
        <div className="card mb-4">
          <div className="card-header">Spouse Details</div>
          <div className="card-body">
            {Object.keys(formData.spouse).map((key) => (
              <div className="col-md-4 mb-2" key={key}>
                <label className="form-label">
                  {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1")}
                  {key !== "preExistingDisease" ? <span className="text-danger">*</span> : null}
                </label>
                <input
                  type={key === "dob" ? "date" : key.includes("height") || key.includes("weight") ? "number" : "text"}
                  className="form-control"
                  name={key}
                  value={formData.spouse[key]}
                  onChange={(e) => handleChange(e, "spouse")}
                  placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
                  required={key !== "preExistingDisease"}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Children Details */}
        <div className="card mb-4">
          <div className="card-header">Children Details</div>
          <div className="card-body">
            {formData.children.map((child, index) => (
              <div key={index} className="mb-3">
                <h5>Child {index + 1}</h5>
                {Object.keys(child).map((key) => (
                  <div className="col-md-4 mb-2" key={key}>
                    <label className="form-label">
                      {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1")}
                      {key !== "preExistingDisease" ? <span className="text-danger">*</span> : null}
                    </label>
                    <input
                      type={key === "dob" ? "date" : key.includes("height") || key.includes("weight") ? "number" : "text"}
                      className="form-control"
                      name={key}
                      value={child[key]}
                      onChange={(e) => handleChange(e, "children", index)}
                      placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
                      required={key !== "preExistingDisease"}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>

        {/* Required Documents */}
        <div className="card mb-4">
          <div className="card-header">Required Documents</div>
          <div className="card-body">
            <div className="col-md-4 mb-2">
              <label className="form-label">Health Insurance Coverage Details<span className="text-danger">*</span></label>
              <input
                type="text"
                className="form-control"
                name="healthInsurance"
                value={formData.healthInsurance}
                onChange={(e) => handleChange(e, "healthInsurance")}
                placeholder="Health Insurance Coverage Details"
                required
              />
            </div>
            <div className="col-md-4 mb-2">
              <label className="form-label">PAN Card Copy</label>
              <input
                type="file"
                className="form-control"
                name="panCardCopy"
                onChange={(e) => handleFileChange(e, "panCardCopy")}
              />
            </div>
            <div className="col-md-4 mb-2">
              <label className="form-label">Aadhar Card (Front & Back Page)</label>
              <input
                type="file"
                className="form-control"
                name="aadharCard"
                onChange={(e) => handleFileChange(e, "aadharCard")}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <button type="submit" className="btn btn-primary d-flex m-auto col-1">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default HealthPolicyForm;