import React, { useState, useEffect } from "react";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import { Select } from 'antd';
import '../../Config/swal.css';


export default function AddProject() {

    const [userDetails, SetUserDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCusId, setSelectedCusId] = useState('');
    const [customersData, setCustomersData] = useState([]);


    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const [formData, setFormData] = useState({
        name: "",
        clientid: "",
        billing_type: 1,
        start_date: "",
        deadline: "",
        project_cost: "",
        description: "",
        status: 1,
        addedfrom: userDetails.staffid,

        latitude : "",
        longitude : "",
        address : ""
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetCustomers`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setCustomersData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setCustomersData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const customersOptions =  customersData && customersData.map((item) => ({
        value: item.userid,
        label: item.company,
    }));

    const handleCusSelectChange = (selectedOption) => {
        console.log(selectedOption)
        setSelectedCusId(selectedOption);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            formData.addedfrom = userDetails.staffid;
            formData.clientid = selectedCusId;
            formData.billing_type = 1;
            formData.status = 1;

            console.log(formData, 'data sending to api');

            const response = await fetch(`${API_BASE_CRM_URL}AddProjectWithLocation`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (data.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Project added Successfully.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        window.location.reload();
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("Error submitting form");
        }
    };

    return (
        <div>
            <style>
                {`
                .offcanvas.custom-offcanvas {
                    width: 400px !important; /* Adjust this as needed */
                }

                @media (max-width: 576px) {
                    .offcanvas.custom-offcanvas {
                    width: 70% !important; /* Adjust the percentage as needed */
                    }
                }
                `}
            </style>

            <div
                className="offcanvas offcanvas-end custom-offcanvas"
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Add Project</h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        <div className=" mt-2">
                            <label className="form-label">Project</label>
                            <input 
                                type="text"
                                className="form-control"
                                placeholder="Enter project name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-2">
                            <label className="form-label">Customer</label>
                            {/* <select
                                className="form-select"
                                name="clientid"
                                value={formData.clientid}
                                onChange={handleChange}
                            >
                                <option disabled value="">
                                    Choose customer
                                </option>
                                {customersData && customersData.map((item, index) => (
                                    <option key={index} value={item.userid}>{item.company}</option>
                                ))}
                            </select> */}
                            <div className='d-flex'>
                                <Select
                                    showSearch
                                    placeholder="Choose customer"
                                    value={selectedCusId ? selectedCusId : null}
                                    onChange={handleCusSelectChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={customersOptions}
                                    style={{ flexGrow: 1, height: '2.4rem' }}
                                />
                            </div>
                        </div>
                        <div className=" mt-2">
                            <label className="form-label">Start Date</label>
                            <input 
                                type="date"
                                className="form-control"
                                name="start_date"
                                value={formData.start_date}
                                onChange={handleChange}
                            />
                        </div>
                        <div className=" mt-2">
                            <label className="form-label">End Date</label>
                            <input 
                                type="date"
                                className="form-control"
                                name="deadline"
                                value={formData.deadline}
                                onChange={handleChange}
                            />
                        </div>
                        <div className=" mt-2">
                            <label className="form-label">Cost of Project</label>
                            <input 
                                type="number"
                                className="form-control"
                                placeholder="Enter cost of project"
                                name="project_cost"
                                value={formData.project_cost? formData.project_cost : 100000}
                                onChange={handleChange}
                            />
                        </div>
                        <div className=" mt-2">
                            <label className="form-label">Latitude</label>
                            <input 
                                type="number"
                                className="form-control"
                                placeholder="Enter cost of project"
                                name="latitude"
                                value={formData.latitude}
                                onChange={handleChange}
                                onWheel={(e) => e.target.blur()}
                            />
                        </div>
                        <div className=" mt-2">
                            <label className="form-label">Longitude</label>
                            <input 
                                type="number"
                                className="form-control"
                                placeholder="Enter longitude"
                                name="longitude"
                                value={formData.longitude}
                                onChange={handleChange}
                                onWheel={(e) => e.target.blur()}
                            />
                        </div>
                        <div className=" mt-2">
                            <label className="form-label">Address</label>
                            <input 
                                type="text"
                                className="form-control"
                                placeholder="Enter project address"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                            />
                        </div>
                        <div className=" mt-2">
                            <label className="form-label">Description</label>
                            <textarea
                                className="form-control"
                                placeholder="Enter project description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-primary d-flex m-auto mt-4">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
