import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Base from '../../Config/Base';
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Link } from 'react-router-dom';


export default function ViewCustomer() {

  const { cusid } = useParams(); 

  const [customersData, setCustomerData] = useState([]);
  const [customerContactsData, setCustomerContactsData] = useState([]);
  const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetCustomerDetails?CusId=${cusid}`);
                    if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
        
                if (data.Status) {
                    setCustomerData(data.ResultData[0]);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setCustomerData([]); // Set an empty array to avoid errors
                }
            } catch (error) {
                    console.error("Error fetching data:", error);
            } finally {
                    setLoading(false);
            }
        };

        fetchData();
    }, [cusid]);

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
                const response = await fetch(`${API_BASE_CRM_URL}GetCustomerContacts?CusId=${cusid}`);
                    if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
        
                if (data.Status) {
                    setCustomerContactsData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setCustomerContactsData([]);
                }
            } catch (error) {
                    console.error("Error fetching data:", error);
            } finally {
                    setLoading(false);
            }
        };
    
        fetchData();
    }, [cusid]);


  return (
    <Base>
        <div className="page-heade mb-3">
            <div className="row align-items-center">
                <div className="col-md-6">
                    <div className="page-title">
                        <h4>Customers Details</h4>
                        <p className='fw-semibold'>View details</p>
                    </div>
                </div>
                <div className="col-md-6 d-flex justify-content-md-end justify-content-start">
                    <Link to='/customers'>
                        <button className="btn btn-primary" style={{ borderRadius: '10px' }}>
                            <i className="fa-solid fa-arrow-left me-2"></i> Go to List
                        </button>
                    </Link>
                </div>
            </div>
        </div>
        
        <div className="col-xxl-12 col-xl-12">
            <div className="card">
                <div className="card-body" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                    <ul className="nav nav-tabs tab-style-2 nav-justified mb-3 d-sm-flex d-block" id="myTab1" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="order-tab" data-bs-toggle="tab" data-bs-target="#order-tab-pane"
                                type="button" role="tab" aria-controls="order-tab-pane" aria-selected="true">
                                <i className="fa-regular fa-address-book"></i>Personal Info
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="confirmed-tab" data-bs-toggle="tab"
                                data-bs-target="#confirm-tab-pane" type="button" role="tab" aria-controls="confirm-tab-pane"
                                aria-selected="false">
                                <i className="fa-solid fa-map-location-dot"></i>Billing
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="shipped-tab" data-bs-toggle="tab" data-bs-target="#shipped-tab-pane"
                                type="button" role="tab" aria-controls="shipped-tab-pane" aria-selected="false">
                                <i className="fa-solid fa-truck-fast"></i>Shipping
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="contacts-tab" data-bs-toggle="tab" data-bs-target="#contacts-tab-pane"
                                type="button" role="tab" aria-controls="contacts-tab-pane" aria-selected="false">
                                <i className="fa-regular fa-address-book"></i>Contacts
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active text-muted" id="order-tab-pane" role="tabpanel" aria-labelledby="order-tab" tabIndex="0">
                            <div className='row m-3'>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Organization Name</label>
                                    <input
                                        className='form-control'
                                        value={customersData.company || ''}
                                        readOnly
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4'>
                                    <label className='form-label'>Phone Number</label>
                                    <input
                                        className='form-control'
                                        value={customersData.phonenumber || ''}
                                        readOnly
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4'>
                                    <label className='form-label'>Website</label>
                                    <input
                                        className='form-control'
                                        value={customersData.website || ''}
                                        readOnly
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Created On</label>
                                    <input
                                        className='form-control'
                                        value={customersData.datecreated || ''}
                                        readOnly
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4'>
                                    <label className='form-label'>Language</label>
                                    <input
                                        className='form-control'
                                        value={customersData.default_language || ''}
                                        readOnly
                                    />
                                </div>

                                <div className='col-md-4 col-lg-4'>
                                    <label className='form-label'>City</label>
                                    <input
                                        className='form-control'
                                        value={customersData.city || ''}
                                        readOnly
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>State</label>
                                    <input
                                        className='form-control'
                                        value={customersData.state || ''}
                                        readOnly
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4'>
                                    <label className='form-label'>Zipcode</label>
                                    <input
                                        className='form-control'
                                        value={customersData.zip || ''}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade text-muted" id="confirm-tab-pane" role="tabpanel" aria-labelledby="confirmed-tab" tabIndex="0">
                            <div className='row m-3'>
                                <div className='col-md-4 col-lg-4'>
                                    <label className='form-label'>Billing Street</label>
                                    <input
                                        className='form-control'
                                        value={customersData.billing_street || ''}
                                        readOnly
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4'>
                                    <label className='form-label'>Billing City</label>
                                    <input
                                        className='form-control'
                                        value={customersData.billing_city || ''}
                                        readOnly
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4'>
                                    <label className='form-label'>Billing State</label>
                                    <input
                                        className='form-control'
                                        value={customersData.billing_state || ''}
                                        readOnly
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4'>
                                    <label className='form-label'>Billing Zipcode</label>
                                    <input
                                        className='form-control'
                                        value={customersData.billing_zip || ''}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade text-muted" id="shipped-tab-pane" role="tabpanel" aria-labelledby="shipped-tab" tabIndex="0">
                            <div className='row m-3'>
                                <div className='col-md-4 col-lg-4'>
                                    <label className='form-label'>Shipping Street</label>
                                    <input
                                        className='form-control'
                                        value={customersData.shipping_street || ''}
                                        readOnly
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4'>
                                    <label className='form-label'>Shipping City</label>
                                    <input
                                        className='form-control'
                                        value={customersData.shipping_city || ''}
                                        readOnly
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4'>
                                    <label className='form-label'>Shipping State</label>
                                    <input
                                        className='form-control'
                                        value={customersData.shipping_state || ''}
                                        readOnly
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4'>
                                    <label className='form-label'>Shipping Zipcode</label>
                                    <input
                                        className='form-control'
                                        value={customersData.shipping_zip || ''}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade text-muted" id="contacts-tab-pane" role="tabpanel" aria-labelledby="contacts-tab" tabIndex="0">
                            <div className='row m-3'>
                                <div className='table-responsive'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Title</th>
                                                <th>Password</th>
                                                <th>Email</th>
                                                <th>Phone No</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {customerContactsData && customerContactsData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.firstname + item.lastname}</td>
                                                    <td>{item.title}</td>
                                                    <td>{item.password}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.phonenumber}</td>
                                                    <td>
                                                        {item.active === 1 ? (
                                                                <span className="badge bg-outline-success">Active</span>
                                                            ) : (
                                                            <span className="badge bg-outline-danger">Inactive</span>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Base>
  );
}
