
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBlenderPhone, faHome, faBell, faEnvelope, faExpand, faGear, faFile, faArrowRight, faWarehouse, faGears, faNetworkWired, faCubes, faCartShopping, faSackDollar, faCoins, faCreditCard } from '@fortawesome/free-solid-svg-icons';
// import Reminders from './reminders.svg';
import CRMLOGO from '../../Assets/logo.png';
// import Followup from './followup.svg';
// import ChallanSVG from './chalan.svg';
// import Projects from './../Pages/Projects/Projects';


const Base = ({ children }) => {


    const [userDetails, SetUserDetails] = useState([]);

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const navigate = useNavigate();
    const location = useLocation();

    const [reportIsOpen, setReportIsOpen] = useState(false);
    const [configIsOpen, setConfigIsOpen] = useState(false);
    const [projectsIsOpen, setProjectsIsOpen] = useState(false);

    const ReportsToggleDropdown = () => {
        setReportIsOpen(!reportIsOpen);
        setConfigIsOpen(false);
        setProjectsIsOpen(false);
    };

    const ConfigToggleDropdown = () => {
        setConfigIsOpen(!configIsOpen);
        setReportIsOpen(false);
        setProjectsIsOpen(false);
    };

    const ProjectsToggleDropdown = () => {
        setProjectsIsOpen(!projectsIsOpen);
        setReportIsOpen(false);
        setConfigIsOpen(false);
    };

    useEffect(() => {
        const reportPaths = [
            '/leads-report',
            '/customers-report',
            '/reminders-report',
            '/followups-report'
        ];

        if (reportPaths.includes(location.pathname)) {
            setReportIsOpen(true);
        } else {
            setReportIsOpen(false);
        }
    }, [location.pathname]);

    const handleLogout = () => {
        sessionStorage.removeItem('userDetails');
        navigate('/');
    };

    const useDropdownState = (paths, setState) => {
        const location = useLocation();

        useEffect(() => {
            if (paths.includes(location.pathname)) {
                setState(true);
            }
        }, [location.pathname, paths, setState]);
    };

    const reportPaths = [
        '/leads-report',
        '/customers-report',
        '/reminders-report',
        '/followups-report'
    ];

    useDropdownState(reportPaths, setReportIsOpen);

    const projectsPaths = [
        '/projects',
        '/project-locations',
        '/calander',
        '/delivery-challan'
    ];

    useDropdownState(projectsPaths, setProjectsIsOpen);

    const configPaths = [
        '/customers',
        '/followups',
        '/logistic-rate',
    ];

    useDropdownState(configPaths, setConfigIsOpen);



    return (

        <div className="main-wrapper">
            {/* Header */}
            <div className="header">
                <div className="header-left active">
                    <a className="logo logo-normal">
                        <Link to='/dashboard'>
                            <img src={CRMLOGO} alt="crmlogo" />
                        </Link>
                    </a>
                    <a className="logo logo-white">
                        <Link to='/dashboard'>
                            <img src={CRMLOGO} alt="crmlogo" />
                        </Link>
                    </a>
                    <a className="logo-small">
                        <Link to='/dashboard'>
                            <img src={CRMLOGO} alt="crmlogo" />
                        </Link>
                    </a>
                    {/* <a id="toggle_btn" href="javascript:void(0);">
                        <i data-feather="chevrons-left" className="feather-16"></i>
                    </a> */}
                </div>

                {/* <a id="mobile_btn" className="mobile_btn" href="#sidebar">
                    <span className="bar-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </a> */}
                <a className="mobile_btn" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                    <span className="bar-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </a>

                <ul className="nav user-menu">
                    <li className="nav-item nav-searchinputs">
                        <div className="top-nav-search">
                            <a href="javascript:void(0);" className="responsive-search">
                                <i className="fa fa-search"></i>
                            </a>
                            <form action="#" className="dropdown">
                                <div className="searchinputs dropdown-toggle" >
                                    <input type="text" placeholder="Search" />
                                    <div className="search-addon">
                                        <span><i data-feather="x-circle" className="feather-14"></i></span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li>

                    {/* <li className="nav-item dropdown has-arrow main-drop select-store-dropdown">
                        <a href="javascript:void(0);" className="dropdown-toggle nav-link select-store"
                            data-bs-toggle="dropdown">
                            <span className="user-info">
                                <span className="user-letter">
                                    <img src="assets/img/store/store-01.png" alt="Store Logo" className="img-fluid"/>
                                </span>
                                <span className="user-detail">
                                    <span className="user-name">Select Store</span>
                                </span>
                            </span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <a href="javascript:void(0);" className="dropdown-item">
                                <img src="assets/img/store/store-01.png" alt="Store Logo" className="img-fluid"/> Grocery Alpha
                            </a>
                            <a href="javascript:void(0);" className="dropdown-item">
                                <img src="assets/img/store/store-02.png" alt="Store Logo" className="img-fluid"/> Grocery Apex
                            </a>
                            <a href="javascript:void(0);" className="dropdown-item">
                                <img src="assets/img/store/store-03.png" alt="Store Logo" className="img-fluid"/> Grocery Bevy
                            </a>
                            <a href="javascript:void(0);" className="dropdown-item">
                                <img src="assets/img/store/store-04.png" alt="Store Logo" className="img-fluid"/> Grocery Eden
                            </a>
                        </div>
                    </li> */}

                    {/* <li className="nav-item dropdown has-arrow flag-nav nav-item-box">
                        <a className="nav-link dropdown-toggle"
                            role="button">
                            <img src="assets/img/flags/us.png" alt="Language" className="img-fluid"/>
                        </a>
                    </li> */}
                    <li className="nav-item nav-item-box">
                        <a href="javascript:void(0);" id="btnFullscreen">
                            <FontAwesomeIcon icon={faExpand} />
                        </a>
                    </li>
                    <li className="nav-item nav-item-box">
                        <a>
                            <FontAwesomeIcon icon={faEnvelope} />
                            <span className="badge rounded-pill">1</span>
                        </a>
                    </li>
                    <li className="nav-item dropdown nav-item-box">
                        <a href="javascript:void(0);" className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                            <FontAwesomeIcon icon={faBell} />
                            <span className="badge rounded-pill">2</span>
                        </a>
                        <div className="dropdown-menu notifications">
                            <div className="topnav-dropdown-header">
                                <span className="notification-title">Notifications</span>
                                <a href="javascript:void(0)" className="clear-noti"> Clear All </a>
                            </div>
                            <div className="noti-content">
                                <ul className="notification-list">
                                    <li className="notification-message">
                                        <a>
                                            <div className="media d-flex">
                                                <span className="avatar flex-shrink-0">
                                                    <img alt="" src="assets/img/profiles/avatar-02.jpg" />
                                                </span>
                                                <div className="media-body flex-grow-1">
                                                    <p className="noti-details"><span className="noti-title">{userDetails.firstname + " " + userDetails.lastname}</span> added
                                                        new task <span className="noti-title">Patient appointment booking</span>
                                                    </p>
                                                    <p className="noti-time"><span className="notification-time">4 mins ago</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="topnav-dropdown-footer">
                                <a href="activities.html">View all Notifications</a>
                            </div>
                        </div>
                    </li>

                    <li className="nav-item nav-item-box">
                        <a >
                            <FontAwesomeIcon icon={faGear} />
                        </a>
                    </li>
                    <li className="nav-item dropdown has-arrow main-drop">
                        <a href="javascript:void(0);" className="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
                            <span className="user-info">
                                <span className="user-letter">
                                    <img src="assets/img/profiles/avator1.jpg" alt="" className="img-fluid" />
                                </span>
                                <span className="user-detail">
                                    <span className="user-name">{userDetails.firstname + " " + userDetails.lastname}</span>
                                    <span className="user-role">Super Admin</span>
                                </span>
                            </span>
                        </a>
                        <div className="dropdown-menu menu-drop-user">
                            <div className="profilename">
                                <div className="profileset">
                                    <span className="user-img"><img src="assets/img/profiles/avator1.jpg" alt="" />
                                        <span className="status online"></span></span>
                                    <div className="profilesets">
                                        <h6>{userDetails.firstname + " " + userDetails.lastname}</h6>
                                        <h5>Super Admin</h5>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <a className="dropdown-item" > <i className="me-2" data-feather="user"></i> My
                                    Profile</a>
                                {/* <Link to='/customers-report'> */}

                                <a className="dropdown-item" ><i className="me-2"
                                    data-feather="settings"></i>Settings</a>
                                {/* </Link> */}
                                <hr className="m-0" />
                                <a className="dropdown-item logout pb-0" onClick={handleLogout}><img
                                    src="assets/img/icons/log-out.svg" className="me-2" alt="img" />Logout</a>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="dropdown mobile-user-menu">
                    <a href="javascript:void(0);" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                        aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" >My Profile</a>
                        <a className="dropdown-item" >Settings</a>
                        <a className="dropdown-item" onClick={handleLogout}>Logout</a>
                    </div>
                </div>
            </div>

            {/* Sidebar */}
            <div className="sidebar d-none d-md-block d-lg-block " id="sidebar">
                <div className="sidebar-inner slimscroll">
                    <div id="sidebar-menu" className="sidebar-menu">
                        <ul>
                            <li className="submenu-open">
                                <ul>
                                    <li>
                                        <Link to='/dashboard' className={location.pathname === '/dashboard' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faHome} className="me-2 text-primary" />Dashboard
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/leads" className={location.pathname === '/leads' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faBlenderPhone} className="me-2 text-primary" />Leads
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/products" className={location.pathname === '/products' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faCubes} className="me-2 text-primary" />Products
                                        </Link>
                                    </li>
                                    {/* <li className='fw-semibold'>
                                        <Link to="/delivery-chalan"  className={location.pathname === '/delivery-chalan' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faReceipt} className="me-2 text-primary" />Delivery Chalan
                                        </Link>
                                    </li> */}
                                    {/* <li className=' fw-semibold'>
                                        <Link to="/logistic-rate"  className={location.pathname === '/logistic-rate' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faTruckFast} className="me-2 text-primary" />Logistic Rate
                                        </Link>
                                    </li> */}
                                    {/* <li className='mb-2 fw-semibold'>
                                        <Link to="/projects"  className={location.pathname === '/projects' ? 'text-primary' : ''}>
                                            <i className="fa-solid fa-network-wired me-2 text-primary"></i>Projects
                                        </Link>
                                    </li>
                                    <li className='mb-2 fw-semibold'>
                                        <Link to="/project-locations"  className={location.pathname === '/project-locations' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faMapLocationDot} className="me-2 text-primary" />Project Locations
                                        </Link>
                                    </li> */}
                                    {/* <li>
                                        <Link to="/calendar" className={location.pathname === '/calendar' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faCalendarAlt} className="me-2 text-primary" />Calendar
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to="/delivery-challan" className={location.pathname === '/delivery-challan' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faWarehouse} className='me-2 text-primary' />Inventory
                                        </Link>
                                    </li>

                                    <li className='mb-2 fw-semibold'>
                                        <Link to="/purchase-order" className={location.pathname === '/purchase-order' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faCartShopping} className="me-2 text-primary" />Purchase Order
                                        </Link>
                                    </li>

                                    <li className='mb-2 fw-semibold'>
                                        <Link to="/invoice" className={location.pathname === '/invoice' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faSackDollar} className="me-2 text-primary" />Invoice
                                        </Link>
                                    </li>

                                    <li className='mb-2 fw-semibold'>
                                        <Link to="/expenses" className={location.pathname === '/expenses' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faCoins} className="me-2 text-primary" />Expenses
                                        </Link>
                                    </li>

                                    <li className='mb-2 fw-semibold'>
                                        <Link to="/credit-notes" className={location.pathname === '/credit-notes' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faCreditCard} className="me-2 text-primary" />Credit Notes
                                        </Link>
                                    </li>

                                    <li>
                                        <div className="dropdown">
                                            <p className="d-flex" style={{ cursor: 'pointer' }} onClick={ProjectsToggleDropdown}>
                                                <a>
                                                    <span>
                                                        <FontAwesomeIcon icon={faNetworkWired} className="me-2 text-primary" />Projects
                                                        <i className={`fa-solid fa-chevron-${projectsIsOpen ? 'up' : 'down'}  fs-6`} style={{ marginLeft: '6rem' }}></i>
                                                    </span>
                                                </a>
                                            </p>
                                            {projectsIsOpen && (
                                                <div className="dropdown-content" style={{ marginTop: '-20px' }}>
                                                    <ul className='ms-3'>
                                                        <li ><Link to='/projects' className={location.pathname === '/projects' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Projects</Link></li>
                                                        <li><Link to='/project-locations' className={location.pathname === '/project-locations' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Projects Location</Link></li>
                                                        <li><Link to='/calendar' className={location.pathname === '/calendar' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Calendar</Link></li>
                                                        <li><Link to='/delivery-challan' className={location.pathname === '/delivery-challan' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Delivery Challan</Link></li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="dropdown">
                                            <p className="d-flex" style={{ cursor: 'pointer' }} onClick={ConfigToggleDropdown}>
                                                <a>
                                                    <span>
                                                        <FontAwesomeIcon icon={faGears} className="me-2 text-primary" />Config
                                                        <i className={`fa-solid fa-chevron-${configIsOpen ? 'up' : 'down'}  fs-6`} style={{ marginLeft: '6.5rem' }}></i>
                                                    </span>
                                                </a>
                                            </p>
                                            {configIsOpen && (
                                                <div className="dropdown-content" style={{ marginTop: '-20px' }}>
                                                    <ul className='ms-3'>
                                                        <li ><Link to='/customers' className={location.pathname === '/customers' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Customers</Link></li>
                                                        <li><Link to='/followups' className={location.pathname === '/followups' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Followups</Link></li>
                                                        <li><Link to='/logistic-rate' className={location.pathname === '/logistic-rate' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Logistic Rate</Link></li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="dropdown">
                                            <p className="d-flex" style={{ cursor: 'pointer' }} onClick={ReportsToggleDropdown}>
                                                <a>
                                                    <span>
                                                        <FontAwesomeIcon icon={faFile} className="me-2 text-primary" />Reports
                                                        <i className={`fa-solid fa-chevron-${reportIsOpen ? 'up' : 'down'}  fs-6`} style={{ marginLeft: '6rem' }}></i>
                                                    </span>
                                                </a>
                                            </p>
                                            {reportIsOpen && (
                                                <div className="dropdown-content" style={{ marginTop: '-20px' }}>
                                                    <ul className='ms-3'>
                                                        <li ><Link to='/leads-report' className={location.pathname === '/leads-report' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Leads</Link></li>
                                                        <li><Link to='/customers-report' className={location.pathname === '/customers-report' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Customers</Link></li>
                                                        <li><Link to='/reminders-report' className={location.pathname === '/reminders-report' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Reminders</Link></li>
                                                        <li><Link to='/followups-report' className={location.pathname === '/followups-report' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Followups</Link></li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Mobile view */}
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{ width: '60%' }}>
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">Ecotec</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div id="sidebar-menu" className="sidebar-menu">
                        <ul className='fs-6'>
                            <li className="submenu-open">
                                <ul>
                                    <li className='mb-2 fw-semibold'>
                                        <Link to='/dashboard' className={location.pathname === '/dashboard' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faHome} className="me-2 text-primary" />Dashboard
                                        </Link>
                                    </li>
                                    <li className='mb-2 fw-semibold'>
                                        <Link to="/leads" className={location.pathname === '/leads' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faBlenderPhone} className="me-2 text-primary" />Leads
                                        </Link>
                                    </li>
                                    <li className='mb-2 fw-semibold'>
                                        <Link to="/purchase-order" className={location.pathname === '/purchase-order' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faCartShopping} className="me-2 text-primary" />Purchase Order
                                        </Link>
                                    </li>
                                    <li className='mb-2 fw-semibold'>
                                        <Link to="/invoice" className={location.pathname === '/invoice' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faCartShopping} className="me-2 text-primary" />Invoice
                                        </Link>
                                    </li>

                                    <li className='mb-2 fw-semibold'>
                                        <Link to="/expenses" className={location.pathname === '/expenses' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faCartShopping} className="me-2 text-primary" />Expenses
                                        </Link>
                                    </li>

                                    <li className='mb-2 fw-semibold'>
                                        <Link to="/credit-notes" className={location.pathname === '/credit-notes' ? 'text-primary' : ''}>
                                            <FontAwesomeIcon icon={faCartShopping} className="me-2 text-primary" />Credit Notes
                                        </Link>
                                    </li>

                                    <li className='mb-2'>
                                        <div className="dropdown">
                                            <p className="d-flex" style={{ cursor: 'pointer' }} onClick={ProjectsToggleDropdown}>
                                                <a>
                                                    <span>
                                                        <FontAwesomeIcon icon={faCubes} className="me-2 text-primary" />Projects
                                                        <i className={`fa-solid fa-chevron-${projectsIsOpen ? 'up' : 'down'}  fs-6`} style={{ marginLeft: '5.5rem' }}></i>
                                                    </span>
                                                </a>
                                            </p>
                                            {projectsIsOpen && (
                                                <div className="dropdown-content">
                                                    <ul className='ms-3'>
                                                        <li className='mb-2'><Link to='/projects' className={location.pathname === '/projects' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Projects</Link></li>
                                                        <li className='mb-2'><Link to='/project-locations' className={location.pathname === '/project-locations' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Projects Location</Link></li>
                                                        <li className='mb-2'><Link to='/calendar' className={location.pathname === '/calendar' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Calendar</Link></li>
                                                        <li className='mb-2'><Link to='/delivery-challan' className={location.pathname === '/delivery-challan' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Delivery Challan</Link></li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                    <li className='mb-2'>
                                        <div className="dropdown">
                                            <p className="d-flex" style={{ cursor: 'pointer' }} onClick={ConfigToggleDropdown}>
                                                <a>
                                                    <span>
                                                        <FontAwesomeIcon icon={faGears} className="me-2 text-primary" />Config
                                                        <i className={`fa-solid fa-chevron-${configIsOpen ? 'up' : 'down'}  fs-6`} style={{ marginLeft: '6rem' }}></i>
                                                    </span>
                                                </a>
                                            </p>
                                            {configIsOpen && (
                                                <div className="dropdown-content">
                                                    <ul className='ms-3'>
                                                        <li className='mb-2'><Link to='/customers' className={location.pathname === '/customers' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Customers</Link></li>
                                                        <li className='mb-2'><Link to='/followups' className={location.pathname === '/followups' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Followups</Link></li>
                                                        <li className='mb-2'><Link to='/logistic-rate' className={location.pathname === '/logistic-rate' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Logistic Rate</Link></li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </li>

                                    <li className='mb-2'>
                                        <div className="dropdown">
                                            <p className="d-flex" style={{ cursor: 'pointer' }} onClick={ReportsToggleDropdown}>
                                                <a>
                                                    <span>
                                                        <FontAwesomeIcon icon={faFile} className="me-3 text-primary" />Reports
                                                        <i className={`fa-solid fa-chevron-${reportIsOpen ? 'up' : 'down'}  fs-6`} style={{ marginLeft: '5.6rem' }}></i>
                                                    </span>
                                                </a>
                                            </p>
                                            {reportIsOpen && (
                                                <div className="dropdown-content">
                                                    <ul className='ms-3'>
                                                        <li className='mb-2'><Link to='/leads-report' className={location.pathname === '/leads-report' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Leads</Link></li>
                                                        <li className='mb-2'><Link to='/customers-report' className={location.pathname === '/customers-report' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Customers</Link></li>
                                                        <li className='mb-2'><Link to='/reminders-report' className={location.pathname === '/reminders-report' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Reminders</Link></li>
                                                        <li className='mb-2'><Link to='/followups-report' className={location.pathname === '/followups-report' ? 'text-primary' : ''}><FontAwesomeIcon icon={faArrowRight} className="me-2" />Followups</Link></li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Page Content */}
            <div className="page-wrapper" >
                <div className="content">
                    {children}
                </div>
            </div>
        </div>

    )
}

export default Base;

