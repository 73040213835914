
import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Link } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from 'antd';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import '../../Config/swal.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";


export default function AddInvoice() {

    const [userDetails, SetUserDetails] = useState([]);
    const  navigate = useNavigate();

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const [selectedCustomerid, setSelectedCustomerid] = useState("");
    const [selectedprojectid, setSelectedProjectid] = useState("");
    const [selectedStatusid, setSelectedStatusid] = useState("");
    const [selectedStaffid, setSelectedStaffid] = useState("");

    const [customersData, setCustomersData] = useState([]);
    const [projectsData, setProjectsData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [staffData, setStaffData] = useState([]);
    const [taxesData, setTaxesData] = useState([]);
    
    const [description, setDescription] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [invoiceDate, setInvoiceDate] = useState('');
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetCustomers`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setCustomersData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setCustomersData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetStaff`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setStaffData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setStaffData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetTaxes`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setTaxesData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setTaxesData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetProducts`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setProductsData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setProductsData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedCustomerid) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${API_BASE_CRM_URL}GetProjectsByCusId?CusID=${selectedCustomerid}`);
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    const data = await response.json();
                    if (data.Status) {
                        setProjectsData(data.ResultData);
                    } else {
                        console.error("Fetched data is not an array:", data);
                        setProjectsData([]);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
        }
    }, [selectedCustomerid]);

    const customerOptions =  customersData && customersData.map((item) => ({
        value: item.userid,
        label: item.company,
    }));

    const projectOptions =  projectsData && projectsData.map((item) => ({
        value: item.id,
        label: item.name,
    }));
    
    const productOptions =  productsData && productsData.map((item) => ({
        value: item.long_description,
        label: item.description,
        rate: parseFloat(item.rate),
    }));

    const staffOptions =  staffData && staffData.map((item) => ({
        value: item.staffid,
        label: item.firstname + " " + item.lastname,
    }));

    const taxOptions =  taxesData && taxesData.map((item) => ({
        key: item.id,
        value: item.taxrate,
        label: item.name + " " + item.taxrate,
    }));

    const statusOptions =  [
        {"value": 1, "label" : "Draft"},
        {"value": 2, "label" : "Sent"},
        {"value": 5, "label" : "Expired"},
        {"value": 3, "label" : "Declined"},
        {"value": 4, "label" : "Accepted"},
    ];

    const handleCustomerChange = (selectedone) => {
        setSelectedCustomerid(selectedone);
    };

    const handleProjectChange = (selectedone) => {
        setSelectedProjectid(selectedone);
    };

    const handleStatusChange = (selectedone) => {
        setSelectedStatusid(selectedone);
    };

    const handleStaffChange = (selectedone) => {
        setSelectedStaffid(selectedone);
    };

    const handleInvoiceDateChange = (event) => {
        setInvoiceDate(event.target.value);
    };

    const handledueDateChange = (event) => {
        setDueDate(event.target.value);
    };

    const addRow = (product) => {
        // Check if the product is already in the list
        if (rows.some(row => row.description === product.label)) {
            Swal.fire({
                title: "Product already added",
                text: "This product is already in the list.",
                icon: "warning",
                confirmButtonText: "OK"
            });
            return;
        }

        const newRow = { 
            description: product.label, 
            long_description: product.value || '',
            qty: '', 
            rate: product.rate,
            tax: '',
            amount: 0,
            item_order: rows.length + 1 
        };

        setRows([...rows, newRow]);
        // setSelectedProducts(null);
    };

    const handleProductChange = (value, option) => {
        addRow(option); 
    };

    const handleInputChange = (index, field, value) => {
        const newRows = rows.map((row, rowIndex) => {
            if (rowIndex === index) {
                const updatedRow = { ...row, [field]: parseFloat(value) || 0 };
                return { ...updatedRow, amount: calculateAmount(updatedRow) };
            }
            return row;
        });
        setRows(newRows);
    };

    const handleTaxChange = (index, value) => {
        const newRows = rows.map((row, rowIndex) => {
            if (rowIndex === index) {
                const updatedRow = { ...row, tax: parseFloat(value) || 0 };
                return { ...updatedRow, amount: calculateAmount(updatedRow) };
            }
            return row;
        });
        setRows(newRows);
    };

    const calculateAmount = ({ qty, rate, tax }) => {
        const quantity = parseFloat(qty) || 0;
        const rateValue = parseFloat(rate) || 0;
        const taxRate = parseFloat(tax) || 0;
        return (quantity * rateValue * (1 + taxRate / 100)).toFixed(2);
    };

    const calculateTotal = () => {
        return rows.reduce((total, row) => total + parseFloat(row.amount || 0), 0).toFixed(2);
    };

    const handleDelete = (index) => {
        const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
        setRows(newRows);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const totalAmount = calculateTotal();

        const purchaseData = {
            sent: 1,
            datesend: new Date().toISOString().split('T')[0],
            clientid: selectedCustomerid,
            number: "2",
            prefix: "INV",
            number_format: '0001',
            datecreated: new Date().toISOString().split('T')[0],
            date: invoiceDate,
            duedate: dueDate,
            currency: 1,
            subtotal: totalAmount,
            total_tax: "",
            total: totalAmount,
            adjustment: 0.00,
            addedfrom: userDetails.staffid,
            hash: "qq",
            status: selectedStatusid,
            clientnote: description,
            adminnote: description,
            last_overdue_reminder : "null",
            cancel_overdue_reminders : "null",
            allowed_payment_modes: 'card',
            discount_percent: "",
            discount_total: "",
            discount_type: "percentage",
            recurring: false,
            recurring_type: "monthly",
            custom_recurring : 0,
            cycles: 0,
            total_cycles: 0,
            is_recurring_from : null,
            last_recurring_date: null,
            terms: "payent deu wih th in 15 days",
            sale_agent: selectedStaffid,
            billing_street: "",
            billing_city: "",
            billing_state: "",
            billing_zip: "",
            billing_country: 1,
            shipping_street: "",
            shipping_city: "",
            shipping_state: "",
            shipping_zip: "",
            shipping_country: 1,
            include_shipping: 1,
            show_shipping_on_invoice: 1,
            show_quantity_as: 1,
            project_id: selectedprojectid,
            prefex_sass_packageid : null,
            subscription_id : 0,
            short_link : "",
            products: rows,
        };

        console.log(purchaseData, 'data sending to api');
    
        try {
            const response = await fetch(`${API_BASE_CRM_URL}AddInvoice`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(purchaseData),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log(result, 'rrrrrrrrrrrrrrrrrrr');
            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Invoice Added Successfully.`,
                    icon: "success",
                     customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        // window.location.reload();
                        navigate('/invoice')
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                     customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };
    

    return (
        <Base>
            
            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>Add Invoice</h4>
                        <h6>Enter fields for add invocie</h6>
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <Link to='/purchase-order' className="btn btn-added"><i className="fa-solid fa-arrow-left me-1"></i>Go to List</Link>
                    </li>
                </ul>
            </div>
            
            <div className="card table-list-card">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row m-2">
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Customer</label>
                                <Select
                                    showSearch
                                    placeholder="Choose customer"
                                    value={selectedCustomerid || null}
                                    onChange={handleCustomerChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={customerOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div> 
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Project</label>
                                <Select
                                    showSearch
                                    placeholder="Choose project"
                                    value={selectedprojectid || null}
                                    onChange={handleProjectChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={projectOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Status</label>
                                <Select
                                    showSearch
                                    placeholder="Choose status"
                                    value={selectedStatusid || null}
                                    onChange={handleStatusChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={statusOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Invoice Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={invoiceDate}
                                    onChange={handleInvoiceDateChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Due Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={dueDate}
                                    onChange={handledueDateChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Sale Agent</label>
                                <Select
                                    showSearch
                                    placeholder="Choose agent"
                                    value={selectedStaffid || null}
                                    onChange={handleStaffChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={staffOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Product</label>
                                <Select
                                    showSearch
                                    placeholder="Choose product"
                                    onChange={handleProductChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={productOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="col-12 my-3 table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                            <th>Rate</th>
                                            <th>Tax</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map((row, index) => (
                                            <tr key={row.id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Product name"
                                                        value={row.description}
                                                        onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                                                        readOnly
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Quantity"
                                                        style={{ width: '5rem' }}
                                                        value={row.qty}
                                                        onChange={(e) => handleInputChange(index, 'qty', e.target.value)}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Rate"
                                                        style={{ width: '7rem' }}
                                                        value={row.rate}
                                                        onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </td>
                                                <td>
                                                    <Select
                                                        showSearch
                                                        placeholder="Choose tax"
                                                        value={row.tax}
                                                        onChange={(value) => handleTaxChange(index, value)}
                                                        options={taxOptions.map(option => ({ ...option, key: option.key }))}
                                                        style={{ height: '2.4rem', width: '7rem' }}
                                                    />
                                                </td>
                                                <td></td>
                                                <td>
                                                    <button className="btn" onClick={() => handleDelete(index)}>
                                                        <FontAwesomeIcon icon={faTrashCan} className="text-danger fs-6" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 my-3 text-end">
                                <h5>Total Amount: {calculateTotal()}</h5>
                            </div>
                            <div className="d-flex flex-column col-lg-12 col-md-12">
                                <label className="form-label">Description</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Enter notes..."
                                />
                            </div>
                            <div className="d-flex flex-column col-2 m-auto mt-2">
                                <button className="btn btn-primary" type="submit">{loading ? 'Submitting...' : 'Submit'}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Base>
    )
}