
import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import Pagination from '../../Config/Pagination';
import '../../../Assets/popup.css';
import PDFIMG from '../../Config/pdf.svg';
import EXCELIMG from '../../Config/excel.svg';
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../../Config/loading.css';
import { Link, useNavigate } from "react-router-dom";

export default function Expenses() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [expensesData, setExpensesData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [locationViewObj, setLocationViewObj] = useState([]);
    const [locationEditObj, setLocationEditObj] = useState([]);

    useEffect(() => {
        const userDetails = sessionStorage.getItem('userDetails');
        if (!userDetails) {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
                const response = await fetch(`${API_BASE_CRM_URL}GetExpenses`);
                    if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
        
                if (data.Status) {
                    setExpensesData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setExpensesData([]);
                }
            } catch (error) {
                    console.error("Error fetching datasssss:", error);
            } finally {
                    setLoading(false);
            }
        };
    
        fetchData();
    }, []);

    const handleReload =() => {
        window.location.reload();
    }

    const showLocationViewModal = (obj) => {
        setLocationViewObj(obj);
    };

    const showLocationEditModal = (obj) => {
        setLocationEditObj(obj);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const filteredItems = expensesData.filter(item => 
        (item.proj_name && item.proj_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.category_name && item.category_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.expense_name && item.expense_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.cus_name && item.cus_name.toLowerCase().includes(searchTerm.toLowerCase()))
    );    
    
    // Calculate indices for slicing data
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
    
        return `${day}-${month}-${year}`;
    };

    const handleExcelDownload = () => {
        if (expensesData.length === 0) {
            return; // Don't download if there is no data
        }

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert your data to worksheet format
        const wsData = expensesData.map((item) => ({
            'Company': item.company,
            'Date Created': formatDate(item.datecreated),
            'City': item.city,
            'State': item.state,
            'Zipcode': item.zip,
            'Website': item.website,
        }));

        const ws = XLSX.utils.json_to_sheet(wsData);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'CustoemrsData');

        // Save the workbook to a file
        XLSX.writeFile(wb, 'CustoemrsData.xlsx');
    };

    const handlePDFDownload = () => {
        const doc = new jsPDF();

        // Set properties for PDF
        doc.setProperties({
            title: 'Customers Data',
            author: 'Your Name',
            creator: 'Your Application'
        });

        // Add data to PDF
        const tableData = []; // Your data array here

        // Add a header row
        const headers = ['Company', 'Phone Number', 'Created Date', 'City', 'State', 'Zip', 'Website'];
        tableData.push(headers);

        // Add rows
        expensesData.forEach(item => {
            const row = [];
            row.push(item.company, item.phonenumber, formatDate(item.datecreated), item.city, item.state, item.zip, item.website);
            tableData.push(row);
        });

        // Generate table
        doc.autoTable({
            head: [headers],
            body: tableData.slice(1),
        });

        // Save the PDF
        doc.save('expensesData.pdf');
    };

    return (
        <Base>
            
            <div className="page-header">
    <div className="d-flex justify-content-between flex-wrap">
        <div className="page-title">
            <h4>Expense</h4>
            <h6>Manage your Expenses</h6>
        </div>
      
    </div>
    <div className="d-flex flex-wrap align-items-center mt-3">
        <div className="flex-fill d-md-flex justify-content-between align-items-center">
            <div className="input-group mb-2 mb-md-0">
                <input 
                    type="text" 
                    name="search" 
                    placeholder="Search..." 
                    className="form-control" 
                    value={searchTerm} 
                    onChange={(e) => setSearchTerm(e.target.value)} 
                />
                <button className="btn btn-primary" type="button">
                    <i className="fas fa-search"></i>
                </button>
            </div>
        </div>
        <ul className="table-top-head d-flex list-unstyled mb-0 flex-wrap">
            <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf" onClick={handlePDFDownload}>
                    <img src="assets/img/icons/pdf.svg" alt="PDF" />
                </a>
            </li>
            <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel" onClick={handleExcelDownload}>
                    <img src="assets/img/icons/excel.svg" alt="Excel" />
                </a>
            </li>
            {/* <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print">
                    <i className="fa-solid fa-print fs-5"></i>
                </a>
            </li> */}
            <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh">
                    <i className="fa-solid fa-arrow-rotate-left fs-5"></i>
                </a>
            </li>
            <li className="ms-2 ms-md-3">
                <a data-bs-toggle="tooltip" data-bs-placement="top" title="Collapse" id="collapse-header">
                    <i className="fa-solid fa-chevron-up fs-6"></i>
                </a>
            </li>
            <li className="ms-2 ms-md-3">
            <Link className="btn btn-added" to="/add-expense">
                <i className="fa-solid fa-plus"></i>
            </Link>
            </li>
        </ul>
    </div>
</div>
            <div className="card table-list-card">
                <div className="card-body">
                    <div className="table-responsive product-list d-none d-lg-block d-md-block">
                        <table className="table dataew">
                            <thead>
                                <tr>
                                    <th className="no-sort">#</th>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Category</th>
                                    <th>Amount</th>
                                    <th>Customer</th>
                                    <th>Project</th>
                                    <th>Payment Type</th>
                                    <th className="no-sort">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {loading ? 
                                <td colSpan={12} className="text-center">
                                    <p className="text-center">
                                        <div class="containers m-auto">
                                            <div class="dot"></div>
                                            <div class="dot"></div>
                                            <div class="dot"></div>
                                            <div class="dot"></div>
                                            <div class="dot"></div>
                                            <div class="dot"></div>
                                        </div>
                                    </p>
                                </td> :
                                currentItems && currentItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>{indexOfFirstItem + index + 1}</td>
                                        <td>{item.expense_name}</td>
                                        <td>{formatDate(item.date)}</td>
                                        <td>{item.category_name}</td>
                                        <td>{item.amount}</td>
                                        <td>{item.cus_name ? item.cus_name : 'N/A'}</td>
                                        <td>{item.proj_name}</td>
                                        <td>{item.paymentmode}</td>
                                        {/* <td>
                                            {item.IsActive === 1 ? (
                                                    <span className="badge bg-outline-success">Active</span>
                                                ) : (
                                                <span className="badge bg-outline-danger">Inactive</span>
                                            )}
                                        </td> */}
                                        <td className="action-table-data">
                                            <div className="edit-delete-action">
                                                <Link to={`/view-expense/${item.id}`} className="me-2 edit-icon  p-2" >
                                                    <i className="fa-regular fa-eye"></i>
                                                </Link>
                                                <Link
                                                    to={`/edit-expense/${item.id}`}
                                                    className="me-2 p-2"  
                                                >
                                                    <i className="fa-regular fa-pen-to-square text-info"></i>
                                                </Link>
                                                {/* <a 
                                                    className="confirm-text p-2" 
                                                    title={item.IsActive === 1 ? 'Active' : 'Inactive'}
                                                    onClick={() => activeLogisticRate(item.id)}
                                                >
                                                    <i className={`fa-solid fa-toggle-${item.IsActive ? 'off text-danger' : 'on text-success'} me-2`}></i>
                                                </a> */}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={expensesData.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    </div>

                    {/* Mobile View */}
                    <div className="row d-block d-md-none d-lg-none" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        {loading ? 
                            <td colSpan={12} className="text-center">
                                <p className="loader m-auto my-5"></p>
                            </td> : 
                            currentItems && currentItems.map((item, index) => ( 
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6" key={index}>
                                <div className="employee-grid-profile">
                                    <div className="profile-head">
                                        <p>{formatDate(item.createdon)}</p>
                                        <div className="profile-head-action">
                                            {item.IsActive === 1 ? (
                                                <span className="badge bg-outline-success text-center w-auto me-1">Active</span>
                                            ) : (
                                                <span className="badge bg-outline-danger text-center w-auto me-1">Inactive</span>
                                            )}
                                            <div className="dropdown profile-action">
                                                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                        className="feather feather-more-vertical feather-user">
                                                        <circle cx="12" cy="12" r="1"></circle>
                                                        <circle cx="12" cy="5" r="1"></circle>
                                                        <circle cx="12" cy="19" r="1"></circle>
                                                    </svg></a>
                                                <ul className="dropdown-menu">
                                                    <li className=" mt-2">
                                                        <a 
                                                            className="dropdown-item"  
                                                            data-bs-toggle="offcanvas" 
                                                            data-bs-target="#offcanvasRightView" 
                                                            aria-controls="offcanvasRight"
                                                            onClick={() => showLocationViewModal(item)}
                                                        >
                                                            <i className="fa-regular fa-eye me-2"></i> View
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a 
                                                            className="dropdown-item"  
                                                            data-bs-toggle="offcanvas" 
                                                            data-bs-target="#offcanvasRightEdit" 
                                                            aria-controls="offcanvasRight"
                                                            onClick={() => showLocationEditModal(item)}
                                                        >
                                                            <i className="fa-regular fa-pen-to-square me-2 text-info"></i>Edit
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item confirm-text mb-0">
                                                            <i className="fa-regular fa-trash-can me-2 text-danger"></i>Delete
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <table className="table">
                                        <tbody>
                                            <tr className="text-start">
                                                <th>{item.pro_name}</th>
                                                <th>{item.cus_name ? item.cus_name : 'N/A'}</th>
                                            </tr>
                                            <tr className="text-start">
                                                <th>{item.latitude}</th>
                                                <th>{item.longitude}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                         ))}
                    </div>
                </div>
            </div>
        </Base>
    )
}