import React, { useState, useEffect } from 'react';
import Base from '../../Config/Base';
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import '../../Config/swal.css';


export default function AddProdcut() {
    
    const [userDetails, SetUserDetails] = useState([]);
    const navigate = useNavigate();
    
    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    useEffect(() => {
        const userDetails = sessionStorage.getItem('userDetails');
        if (!userDetails) {
            navigate('/');
        }
    }, [navigate]);
    
    const [loading, setLoading] = useState(false);
    const [taxesData, setTaxesdata] = useState([]);
    const [selectedTax1Id, setSelectedTax1Id] = useState(null);
    const [selectedTax2Id, setSelectedTax2Id] = useState(null);

    const [formValues, setFormValues] = useState({
        name: '',
        rate: '',
        unit: '',
        tax1: '',
        tax2: '',
        description: '',
    });

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
                const response = await fetch(`${API_BASE_CRM_URL}GetTaxes`);
                    if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
        
                if (data.Status) {
                    setTaxesdata(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setTaxesdata([]);
                }
            } catch (error) {
                    console.error("Error fetching data:", error);
            } finally {
                    setLoading(false);
            }
        };
    
        fetchData();
    }, []);

    const taxOptions =  taxesData && taxesData.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    const handleTax1SelectChange = (selectedOption) => {
        setSelectedTax1Id(selectedOption);
    };

    const handleTax2SelectChange = (selectedOption) => {
        setSelectedTax2Id(selectedOption);
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        formValues.tax1 = selectedTax1Id;
        formValues.tax2 = selectedTax2Id;
        
        try {
            const response = await fetch(`${API_BASE_CRM_URL}AddProduct`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formValues),
            });

            const result = await response.json();
            console.log(result, 'resultttt')

            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Product Add Successfully.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        window.location.reload();
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Base>
            <div 
                className="offcanvas offcanvas-end custom-offcanvas"
                tabIndex="-1"
                id="offcanvasAdd"
                aria-labelledby="offcanvasRightLabel"
            >
                    <style>
                        {`
                        .offcanvas.custom-offcanvas {
                            width: 450px !important; /* Adjust this as needed */
                        }

                        @media (max-width: 576px) {
                            .offcanvas.custom-offcanvas {
                            width: 70% !important; /* Adjust the percentage as needed */
                            }
                        }
                        `}
                    </style>
                    <div className="offcanvas-header">
                        <h4>Add Product</h4>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                        {/* <p className='fw-semibold'>Fill out the form to add a new product</p> */}
                    </div>
                <div className="offcanvas-body ms-2">
                    <form onSubmit={handleSubmit}>
                        <div className='mb-2'>
                            <label className='form-label'>Name<span className='text-danger'>*</span></label>
                            <input
                                className='form-control'
                                type='text'
                                name='name'
                                value={formValues.name}
                                onChange={handleChange}
                                placeholder='Enter name'
                                required
                            />
                        </div>
                        <div className='mb-2'>
                            <label className='form-label'>Price<span className='text-danger'>*</span></label>
                            <input
                                className='form-control'
                                type='text'
                                name='rate'
                                value={formValues.rate}
                                onChange={handleChange}
                                placeholder='Enter price'
                                required
                            />
                        </div>
                        <div className='mb-2'>
                            <label className='form-label'>Units<span className='text-danger'>*</span></label>
                            <input
                                className='form-control'
                                type='text'
                                name='unit'
                                value={formValues.unit}
                                onChange={handleChange}
                                placeholder='Enter unit'
                                required
                            />
                        </div>
                        <div className='mb-2 d-flex flex-column'>
                            <label className='form-label'>
                                State Tax<span className='text-danger'>*</span>
                            </label>
                            <div className='d-flex'>
                                <Select
                                    showSearch
                                    placeholder="Choose tax"
                                    value={selectedTax1Id || null}
                                    onChange={handleTax1SelectChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={taxOptions}
                                    style={{ flexGrow: 1, height: '2.4rem' }}
                                />
                            </div>
                        </div>
                        <div className='mb-2 d-flex flex-column'>
                            <label className='form-label'>
                                Central Tax
                            </label>
                            <div className='d-flex'>
                                <Select
                                    showSearch
                                    placeholder="Choose tax"
                                    value={selectedTax2Id || null}
                                    onChange={handleTax2SelectChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={taxOptions}
                                    style={{ flexGrow: 1, height: '2.4rem' }}
                                />
                            </div>
                        </div>
                        <div className='mb-2'>
                            <label className='form-label'>Description</label>
                            <textarea
                                className='form-control'
                                type='text'
                                name='long_description'
                                value={formValues.description}
                                onChange={handleChange}
                                placeholder='Enter notes...'
                            />
                        </div>
                        <div className='text-center m-auto'>
                            <button type="submit" className="btn btn-primary col-7" disabled={loading}>
                                {loading ? 'Adding...' : 'Add Product'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Base>
    );
}
