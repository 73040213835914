
import React, { useState } from "react";
import Base from "../../Config/Base";
import ViewReminder from "./ViewReminder";
import EditReminder from "./EditReminder";
import PDFIMG from '../../Config/pdf.svg';
import EXCELIMG from '../../Config/excel.svg';

export default function Reminders() {

    return (
        <Base>
            
            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>Reminders</h4>
                        <h6>Manage your Reminders</h6>
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf"><img src={PDFIMG} alt="img"/></a>
                    </li>
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"><img src={EXCELIMG} alt="img"/></a>
                    </li>
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Print"><i data-feather="printer" className="feather-rotate-ccw"></i></a>
                    </li>
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"><i data-feather="rotate-ccw" className="feather-rotate-ccw"></i></a>
                    </li>
                    <li>
                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="Collapse" id="collapse-header"><i data-feather="chevron-up" className="feather-chevron-up"></i></a>
                    </li>
                </ul>
                <div className="d-flex">

                    <div className="page-btn">
                        <a className="btn btn-added" ><i className="fa-solid fa-plus"></i></a>
                    </div>	
                </div>
            </div>
            
            <div className="card table-list-card">
                <div className="card-body">
                    <div className="table-top">
                        <div className="search-set">
                            <div className="search-input">
                                <a href="javascript:void(0);" className="btn btn-searchset"><i data-feather="search" className="feather-search"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive product-list d-none d-lg-block d-md-block">
                        <table className="table datanew">
                            <thead>
                                <tr>
                                    <th className="no-sort">
                                        <label className="checkboxs">
                                            <input type="checkbox" id="select-all"/>
                                            <span className="checkmarks"></span>
                                        </label>
                                    </th>
                                    <th>Customer</th>
                                    <th>Date</th>
                                    <th>Product</th>
                                    <th>Location</th>
                                    <th className="no-sort">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <label className="checkboxs">
                                            <input type="checkbox"/>
                                            <span className="checkmarks"></span>
                                        </label>
                                    </td>
                                    
                                    <td>Pavan R</td>
                                    <td>20-07-2024</td>
                                    <td>Fly Ash</td>
                                    <td>Banjarahills</td>
                                    <td className="action-table-data">
                                        <div className="edit-delete-action">
                                            <a className="me-2 edit-icon  p-2"  data-bs-toggle="modal" data-bs-target="#view-reminder" >
                                                <i data-feather="eye" className="feather-eye"></i>
                                            </a>
                                            <a className="me-2 p-2"  data-bs-toggle="modal" data-bs-target="#edit-reminder" >
                                                <i data-feather="edit" className="feather-edit"></i>
                                            </a>
                                            <a className="confirm-text p-2" href="javascript:void(0);">
                                                <i data-feather="trash-2" className="feather-trash-2"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="row d-block d-md-none d-lg-none">
                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                            <div className="employee-grid-profile">
                                <div className="profile-head">
                                    <label className="checkboxs">
                                        <input type="checkbox"/>
                                        <span className="checkmarks"></span>
                                    </label>
                                    <div className="profile-head-action">
                                        <span className="badge badge-linesuccess text-center w-auto me-1">Active</span>
                                        <div className="dropdown profile-action">
                                            <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    className="feather feather-more-vertical feather-user">
                                                    <circle cx="12" cy="12" r="1"></circle>
                                                    <circle cx="12" cy="5" r="1"></circle>
                                                    <circle cx="12" cy="19" r="1"></circle>
                                                </svg></a>
                                            <ul className="dropdown-menu">
                                                <li className=" mt-2">
                                                    <a className="dropdown-item">
                                                        <i className="fa-regular fa-eye me-2"></i> View
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item"><svg xmlns="http://www.w3.org/2000/svg"
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                            className="feather feather-edit info-img">
                                                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                                        </svg>Edit</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item confirm-text mb-0"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" className="feather feather-trash-2 info-img">
                                                            <polyline points="3 6 5 6 21 6"></polyline>
                                                            <path
                                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                                            </path>
                                                            <line x1="10" y1="11" x2="10" y2="17"></line>
                                                            <line x1="14" y1="11" x2="14" y2="17"></line>
                                                        </svg>Delete</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-info">
                                    <h5>Chalan No : POS001</h5>
                                    <h4>Chaitanya NVN</h4>
                                    <span>Banjarahills - CC Roads</span>
                                </div>
                                <ul className="department">
                                    <li>
                                        Booked
                                        <span>23 Jul 2024</span>
                                    </li>
                                    <li>
                                        Delivery
                                        <span>29 July 2024</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ViewReminder />
            <EditReminder/>
        </Base>
    )
}