
import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Link, useParams } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";

export default function ViewPurchaseOrder() {

    const { PurchaseID } = useParams(); 
    const [estimatePOData, setEstimatePOData] = useState({});
    const [loading, setLoading] = useState(false);
    const [itemsPOData, setItemsPOData] = useState([]);
   
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${API_BASE_CRM_URL}GetPurchaseDetails?ID=${PurchaseID}`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setLoading(false);
                    setEstimatePOData(data.ResultData.estimate);
                    setItemsPOData(data.ResultData.items);
                } else {
                    setLoading(false);
                    console.error("Fetched data is not an array:", data);
                    setEstimatePOData([]);
                    setItemsPOData([]);
                }
            } catch (error) {
                setLoading(false);
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [PurchaseID]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };


    return (
        <Base>
            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>View Purchase Order</h4>
                        {/* <h6>Enter fields for add purchase order</h6> */}
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <Link to='/purchase-order' className="btn btn-added"><i className="fa-solid fa-arrow-left me-1"></i>Go to List</Link>
                    </li>
                </ul>
            </div>
            
            <div className="card table-list-card">
                <div className="card-body">
                    {loading ? 
                        <p className="text-center my-5">
                            <div className="containers m-auto">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                            </div>
                        </p>
                    : 
                        <form>
                            <div className="row m-2">
                                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                    <label className="form-label">Customer</label>
                                    <input 
                                        className="form-control"
                                        value={estimatePOData?.cus_name || ''}
                                        readOnly
                                    />
                                </div> 
                                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                    <label className="form-label">Project</label>
                                    <input 
                                        className="form-control"
                                        value={estimatePOData?.proj_name || ''}
                                        readOnly
                                    />
                                </div>
                                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                    <label className="form-label">Status</label>
                                    {(() => {
                                        switch (estimatePOData?.status) {
                                            case 1:
                                                return <span className="badge bg-outline-warning col-3">Draft</span>;
                                            case 2:
                                                return <span className="badge bg-outline-info">Sent</span>;
                                            case 3:
                                                return <span className="badge bg-outline-danger">Declined</span>;
                                            case 4:
                                                return <span className="badge bg-outline-success">Accepted</span>;
                                            case 5:
                                                return <span className="badge bg-outline-secondary">Expired</span>;
                                            default:
                                                return <span className="badge bg-outline-dark">Unknown</span>;
                                        }
                                    })()}
                                </div>
                                
                                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                    <label className="form-label">Estimate Date</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={formatDate(estimatePOData?.date) || ''}
                                        readOnly
                                    />
                                </div>
                                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                    <label className="form-label">Expiry Date</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={formatDate(estimatePOData?.expirydate) || ''}
                                        readOnly
                                    />
                                </div>
                                <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                    <label className="form-label">Sale Agent</label>
                                    <input 
                                        className="form-control"
                                        value={`${estimatePOData?.staff_firstname || ''} ${estimatePOData?.staff_lastname || ''}`}
                                        readOnly
                                    />
                                </div>
                            
                                <div className="col-12 my-3">
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Product</th>
                                                <th>Quantity</th>
                                                <th>Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {itemsPOData?.map((row, index) => (
                                                <tr key={row.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{row.description}</td>
                                                    <td>{row.qty}</td>
                                                    <td>{row.rate}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 my-3 text-end">
                                    <h5>Total Amount: {estimatePOData?.total}</h5>
                                </div>
                                <div className="d-flex flex-column col-lg-12 col-md-12">
                                    <label className="form-label">Description</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        value={estimatePOData?.clientnote}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </Base>
    )
}