
import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Link, useParams } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from 'antd';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import '../../Config/swal.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import '../../Config/swal.css';

export default function EditPurchaseOrder() {

    const { PurchaseID } = useParams();

    const [userDetails, SetUserDetails] = useState([]);
    const  navigate = useNavigate();

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const [estimatePOData, setEstimatePOData] = useState({});
    const [itemsPOData, setItemsPOData] = useState([]);

    const [selectedCustomerid, setSelectedCustomerid] = useState("");
    const [selectedprojectid, setSelectedProjectid] = useState("");
    const [selectedStatusid, setSelectedStatusid] = useState("");
    const [selectedStaffid, setSelectedStaffid] = useState("");

    const [customersData, setCustomersData] = useState([]);
    const [projectsData, setProjectsData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [staffData, setStaffData] = useState([]);
    const [taxesData, setTaxesData] = useState([]);
    
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        id: PurchaseID,
        sent: 1,
        datesend: "",
        clientid: '',
        project_id: '',
        number: '',
        prefix: "",
        number_format: "",
        date: "",
        expirydate: "",
        currency: "",
        subtotal: "",
        total_tax: "",
        total: "",
        adjustment: 0.00,
        addedfrom: "",
        status: "",
        clientnote: "",
        adminnote: "",
        discount_percent: "",
        discount_total: "",
        discount_type: "",
        reference_no: "",
        sale_agent: "",
        billing_street: "",
        billing_city: "",
        billing_state: "",
        billing_zip: "",
        billing_country: "",
        shipping_street: "",
        shipping_city: "",
        shipping_state: "",
        shipping_zip: "",
        shipping_country: "",
        show_shipping_on_estimate: true,
        products: []
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${API_BASE_CRM_URL}GetPurchaseDetails?ID=${PurchaseID}`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setLoading(false);
                    setEstimatePOData(data.ResultData.estimate);
                    setItemsPOData(data.ResultData.items);
                    setFormValues({
                        id: data.ResultData.estimate.id || "",
                        sent: data.ResultData.estimate.sent || "",
                        datesend: data.ResultData.estimate.datesend || "",
                        clientid: data.ResultData.estimate.clientid || "",
                        project_id: data.ResultData.estimate.project_id || "",
                        number: data.ResultData.estimate.number || "",
                        prefix: data.ResultData.estimate.prefix || "",
                        number_format: data.ResultData.estimate.number_format || "",
                        date: formatDate(data.ResultData.estimate.date || ""),
                        expirydate: formatDate(data.ResultData.estimate.expirydate || ""),
                        currency: data.ResultData.estimate.currency || "",
                        subtotal: data.ResultData.estimate.subtotal || "",
                        total_tax: data.ResultData.estimate.total_tax || "",
                        total: data.ResultData.estimate.total || "",
                        adjustment: data.ResultData.estimate.adjustment || "",
                        addedfrom: data.ResultData.estimate.addedfrom || "",
                        status: data.ResultData.estimate.status || "",
                        clientnote: data.ResultData.estimate.clientnote || "",
                        adminnote: data.ResultData.estimate.adminnote || "",
                        discount_percent: data.ResultData.estimate.discount_percent || "",
                        discount_total: data.ResultData.estimate.discount_total || "",
                        discount_type: data.ResultData.estimate.discount_type || "",
                        reference_no: data.ResultData.estimate.reference_no || "",
                        sale_agent: data.ResultData.estimate.sale_agent || "",
                        billing_street: data.ResultData.estimate.billing_street || "",
                        billing_city: data.ResultData.estimate.billing_city || "",
                        billing_state: data.ResultData.estimate.billing_state,
                        billing_zip: data.ResultData.estimate.billing_zip || "",
                        billing_country: data.ResultData.estimate.billing_country || "",
                        shipping_street: data.ResultData.estimate.shipping_street || "",
                        shipping_city: data.ResultData.estimate.shipping_city || "",
                        shipping_state: data.ResultData.estimate.shipping_state || "",
                        shipping_zip: data.ResultData.estimate.shipping_zip || "",
                        shipping_country: data.ResultData.estimate.shipping_country || "",
                        show_shipping_on_estimate: data.ResultData.estimate.show_shipping_on_estimate || "",
                    });

                    setSelectedCustomerid(data.ResultData.estimate.clientid || '');
                    setSelectedProjectid(data.ResultData.estimate.project_id || '');
                    setSelectedStaffid(data.ResultData.estimate.sale_agent || '');
                    setSelectedStatusid(data.ResultData.estimate.status || '');
                } else {
                    setLoading(false);
                    console.error("Fetched data is not an array:", data);
                    setEstimatePOData([]);
                    setItemsPOData([]);
                }
            } catch (error) {
                setLoading(false);
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [PurchaseID]);

    const handleCustomerChange = (selectedOption) => {
        setSelectedCustomerid(selectedOption);
        setFormValues(prevData => ({
            ...prevData,
            clientid: selectedOption
        }));
    };

    const handleProjectChange = (selectedOption) => {
        setSelectedProjectid(selectedOption);
        setFormValues(prevData => ({
            ...prevData,
            project_id: selectedOption
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const formatDate = (isoString) => {
        if (!isoString) return '';
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetCustomers`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setCustomersData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setCustomersData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetStaff`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setStaffData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setStaffData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetTaxes`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setTaxesData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setTaxesData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetProducts`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setProductsData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setProductsData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedCustomerid) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${API_BASE_CRM_URL}GetProjectsByCusId?CusID=${selectedCustomerid}`);
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    const data = await response.json();
                    if (data.Status) {
                        setProjectsData(data.ResultData);
                    } else {
                        console.error("Fetched data is not an array:", data);
                        setProjectsData([]);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
        }
    }, [selectedCustomerid]);

    const customerOptions =  customersData && customersData.map((item) => ({
        value: item.userid,
        label: item.company,
    }));

    const projectOptions =  projectsData && projectsData.map((item) => ({
        value: item.id,
        label: item.name,
    }));
    
    const productOptions =  productsData && productsData.map((item) => ({
        value: item.long_description,
        label: item.description,
        rate: parseFloat(item.rate),
    }));

    const staffOptions =  staffData && staffData.map((item) => ({
        value: item.staffid,
        label: item.firstname + " " + item.lastname,
    }));

    const taxOptions =  taxesData && taxesData.map((item) => ({
        key: item.id,
        value: item.taxrate,
        label: item.name + " " + item.taxrate,
    }));

    const statusOptions =  [
        {"value": 1, "label" : "Draft"},
        {"value": 2, "label" : "Sent"},
        {"value": 5, "label" : "Expired"},
        {"value": 3, "label" : "Declined"},
        {"value": 4, "label" : "Accepted"},
    ];

    const handleStatusChange = (selectedone) => {
        setSelectedStatusid(selectedone);
    };

    const handleStaffChange = (selectedone) => {
        setSelectedStaffid(selectedone);
    };

    useEffect(() => {
        // Initialize rows with fetched product data if needed
        setRows(itemsPOData.map(item => ({
            id: item.id || null,
            rel_id: item.rel_id || null,
            description: item.description,
            long_description: item.long_description || '',
            qty: item.qty || '',
            rate: item.rate || '',
            tax: item.tax || '',
            amount: 0,
            item_order: item.item_order || itemsPOData.length + 1
        })));
    }, [itemsPOData]);

    const addRow = (product) => {
        if (rows.some(row => row.description === product.label)) {
            Swal.fire({
                title: "Product already added",
                text: "This product is already in the list.",
                icon: "warning",
                confirmButtonText: "OK"
            });
            return;
        }
    
        const newRow = { 
            description: product.label, 
            long_description: product.value || '',
            qty: '', 
            rate: product.rate,
            tax: '',
            amount: 0,
            item_order: rows.length + 1 
        };
    
        setRows([...rows, newRow]);
    };
    

    const handleProductChange = (value, option) => {
        addRow(option); 
    };

    // const handleInputChange = (index, field, value) => {
    //     const newRows = rows.map((row, rowIndex) => {
    //         if (rowIndex === index) {
    //             const updatedRow = { ...row, [field]: value };
    //             return { ...updatedRow, amount: calculateAmount(updatedRow) };
    //         }
    //         return row;
    //     });
    //     setRows(newRows);
    // };  
    
    const handleInputChange = (index, field, value) => {
        const updatedItems = [...itemsPOData];
        updatedItems[index][field] = value;
        setItemsPOData(updatedItems);
    };
    

    // const handleTaxChange = (index, value) => {
    //     const newRows = rows.map((row, rowIndex) => {
    //         if (rowIndex === index) {
    //             const updatedRow = { ...row, tax: parseFloat(value) || 0 };
    //             return { ...updatedRow, amount: calculateAmount(updatedRow) };
    //         }
    //         return row;
    //     });
    //     setRows(newRows);
    // };
    const handleTaxChange = (index, value) => {
        const updatedItems = [...itemsPOData];
        updatedItems[index].tax = value;
        setItemsPOData(updatedItems);
    };
    

    const calculateAmount = ({ qty, rate, tax }) => {
        const quantity = parseFloat(qty) || 0;
        const rateValue = parseFloat(rate) || 0;
        const taxRate = parseFloat(tax) || 0;
        return (quantity * rateValue * (1 + taxRate / 100)).toFixed(2);
    };
    
    const calculateTotal = () => {
        return rows.reduce((total, row) => total + parseFloat(row.amount || 0), 0).toFixed(2);
    };
    

    const handleDelete = (index) => {
        const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
        setRows(newRows);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        const updatedFormValues = {
            ...formValues,
            products: rows,
            total: calculateTotal(),
        };
    
        console.log(updatedFormValues, 'data sending to api');
    
        try {
            const response = await fetch(`${API_BASE_CRM_URL}UpdatePurchaseOrder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedFormValues),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const result = await response.json();
            console.log(result, 'response from api');
            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Purchase Order Updated Successfully.`,
                    icon: "success",
                    custom  : {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        navigate('/purchase-order');
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };
    

    return (
        <Base>
            
            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>Edit Purchase Order</h4>
                        <h6>Modify fields for edit purchase order</h6>
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <Link to='/purchase-order' className="btn btn-added"><i className="fa-solid fa-arrow-left me-1"></i>Go to List</Link>
                    </li>
                </ul>
            </div>
            
            <div className="card table-list-card">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row m-2">
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Customer</label>
                                <Select
                                    showSearch
                                    placeholder="Choose customer"
                                    value={selectedCustomerid || null}
                                    onChange={handleCustomerChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={customerOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div> 
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Project</label>
                                <Select
                                    showSearch
                                    placeholder="Choose project"
                                    value={selectedprojectid || null}
                                    onChange={handleProjectChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={projectOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Status</label>
                                <Select
                                    showSearch
                                    placeholder="Choose status"
                                    value={selectedStatusid || null}
                                    onChange={handleStatusChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={statusOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Estimate Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="date"
                                    // value={estimateDate}
                                    // onChange={handleEstimateDateChange}
                                    value={formValues?.date}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Expiry Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="expirydate"
                                    // value={expiryDate}
                                    // onChange={handleExpiryDateChange}
                                    value={formValues?.expirydate}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Sale Agent</label>
                                <Select
                                    showSearch
                                    placeholder="Choose agent"
                                    value={selectedStaffid || null}
                                    onChange={handleStaffChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={staffOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Product</label>
                                <Select
                                    showSearch
                                    placeholder="Choose product"
                                    onChange={handleProductChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={productOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="col-12 my-3">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                            <th>Rate</th>
                                            <th>Tax</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {itemsPOData.map((row, index) => (
                                            <tr key={row.id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Product name"
                                                        value={row.description}
                                                        onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                                                        readOnly
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Quantity"
                                                        style={{ width: '5rem' }}
                                                        value={row.qty}
                                                        onChange={(e) => handleInputChange(index, 'qty', e.target.value)}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Rate"
                                                        style={{ width: '7rem' }}
                                                        value={row.rate}
                                                        onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </td>
                                                <td>
                                                    <Select
                                                        showSearch
                                                        placeholder="Choose tax"
                                                        value={row.tax}
                                                        onChange={(value) => handleTaxChange(index, value)}
                                                        options={taxOptions.map(option => ({ ...option, key: option.key }))}
                                                        style={{ height: '2.4rem', width: '7rem' }}
                                                    />
                                                </td>
                                                <td></td>
                                                <td>
                                                    <button className="btn" onClick={() => handleDelete(index)}>
                                                        <FontAwesomeIcon icon={faTrashCan} className="text-danger fs-6" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 my-3 text-end">
                                <h5>Total Amount: {calculateTotal()}</h5>
                            </div>
                            <div className="d-flex flex-column col-lg-12 col-md-12">
                                <label className="form-label">Description</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    name="clientnote"
                                    value={formValues?.clientnote}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-2 m-auto mt-2">
                                <button className="btn btn-primary" type="submit">{loading ? 'Submitting...' : 'Submit'}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Base>
    )
}