
import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import Base from "../../Config/Base";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";


export default function EditChallan() {

    const [userDetails, SetUserDetails] = useState([]);
    const { ChallanID } = useParams(); 

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const [challanData, setChallanData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [customersData, setCustomersData] = useState([]);
    const [projectsData, setProjectsData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [selectedCustomerid, setSelectedCustomerid] = useState("");
    const [selectedProductid, setSelectedProductid] = useState("");
    const [selectedprojectid, setSelectedProjectid] = useState("");
    const [formValues, setFormValues] = useState({
        deliveryGoodId: ChallanID,
        rel_type: 'Customer',
        customer_code: '',
        description: '',
        warehouse_id: '',
        staff_id: userDetails.staffid,
        date_c: '',
        addedfrom: userDetails.staffid,
        project: '',
        vehicle_no: '',
        driver_name: '',
        site_latitude: '',
        site_longitude: '',
        warehouse_address: '',
        weight: '',
        distance: '',
        site_address: '',
        shipping_fee: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetChallanDetails?ID=${ChallanID}`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setLoading(false);
                    setChallanData(data.ResultData);
                    setFormValues({
                        customer_code: data.ResultData.customer_code || '',
                        description: data.ResultData.description || '',
                        date_c: formatDate(data.ResultData.date_c) || '',
                        project: data.ResultData.project || '',
                        vehicle_no: data.ResultData.vehicle_no || '',
                        driver_name: data.ResultData.driver_name || '',
                        site_latitude: data.ResultData.site_latitude || '',
                        site_longitude: data.ResultData.site_longitude || '',
                        weight: data.ResultData.weight || '',
                        distance: data.ResultData.distance || '',
                        shipping_fee: data.ResultData.shipping_fee || '',
                    });

                    setSelectedCustomerid(data.ResultData.customer_code || '');
                    setSelectedProjectid(data.ResultData.project || '');
                } else {
                    console.error("Fetched data is not an array:", data);
                    setLoading(false);
                    setChallanData([]);
                }
            } catch (error) {
                setLoading(false);
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetProducts`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setProductsData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setProductsData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetCustomers`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setCustomersData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setCustomersData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedCustomerid) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${API_BASE_CRM_URL}GetProjectsByCusId?CusID=${selectedCustomerid}`);
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    const data = await response.json();
                    if (data.Status) {
                        setProjectsData(data.ResultData);
                    } else {
                        console.error("Fetched data is not an array:", data);
                        setProjectsData([]);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
        }
    }, [selectedCustomerid]);

    const formatDate = (isoString) => {
        if (!isoString) return '';
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleCustomerChange = (selectedOption) => {
        setSelectedCustomerid(selectedOption);
        setFormValues(prevData => ({
            ...prevData,
            customer_code: selectedOption
        }));
    };

    const handleProjectChange = (selectedOption) => {
        setSelectedProjectid(selectedOption);
        setFormValues(prevData => ({
            ...prevData,
            project: selectedOption
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleProductChange = (selectedOption) => {
        setSelectedProductid(selectedOption);
    };

    const customerOptions =  customersData && customersData.map((item) => ({
        value: item.userid,
        label: item.company,
    }));

    const projectOptions =  projectsData && projectsData.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    const productOptions =  productsData && productsData.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    return (
        <Base>
            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>Edit Delivery Chalan</h4>
                        <h6>Modify fields for edit challan</h6>
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <Link to='/delivery-challan' className="btn btn-added"><i className="fa-solid fa-arrow-left me-1"></i>Go to List</Link>
                    </li>
                </ul>
            </div>
            
            <div className="card table-list-card">
                <div className="card-body">
                    <form>
                        <div className="row m-2 mt-3">
                        <div className="d-flex flex-column col-md-4 col-lg-4">
                            <label className="form-label">Customer</label>
                            <Select
                                showSearch
                                placeholder="Choose Customer"
                                value={selectedCustomerid || null}
                                onChange={handleCustomerChange}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={customerOptions}
                                style={{ height: '2.4rem' }}
                            />
                        </div>
                        <div className="d-flex flex-column col-md-4 col-lg-4">
                            <label className="form-label">Project</label>
                            <Select
                                showSearch
                                placeholder="Choose Project"
                                value={selectedprojectid || null}
                                onChange={handleProjectChange}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={projectOptions}
                                style={{ height: '2.4rem' }}
                            />
                        </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Chalan Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={formValues.date_c}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Site Latitude</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter site latitude"
                                    value={formValues.site_latitude}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Site Longitude</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter site longitude"
                                    value={formValues?.site_longitude}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Distance</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Site distance will display here"
                                    value={formValues?.distance}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Vehicle No</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter vehicle no"
                                    value={formValues?.vehicle_no}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Delivery Person</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter driver name"
                                    value={formValues?.driver_name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-md-4 col-lg-4">
                                <label className="form-label">Product</label>
                                <Select
                                    showSearch
                                    placeholder="Choose Product"
                                    value={selectedProductid || null}
                                    onChange={handleProductChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={productOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="col-12 my-3">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                            <th>Weight</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {challanData.commodity_details?.map((row, index) => (
                                            <tr key={row.id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Product name"
                                                        value={row.commodity_name}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Quantity"
                                                        value={row.quantities}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Weight"
                                                        value={row.weight}
                                                    />
                                                </td>
                                                <td>
                                                    <button 
                                                        className="btn">
                                                        <FontAwesomeIcon icon={faTrashCan} className="text-danger fs-6" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex flex-column col-lg-12 col-md-12">
                                <label className="form-label">Description</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter notes..."
                                    value={formValues?.description}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Base>
    );
}

  