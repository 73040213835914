import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Base from '../../Config/Base';
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import '../../Config/swal.css';


export default function EditProject() {

    const { id } = useParams(); 
    const navigate = useNavigate();

    const [projectData, setProjectData] = useState({});
    const [customersData, setCustomersData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [formData, setFormData] = useState({
        id: id,
        name: "",
        description: "",
        status: 0,
        clientid: "",
        billing_type: "",
        start_date: "",
        deadline: "",
        project_created: "",
        date_finished: "",
        progress: 0,
        project_cost: "",
        estimated_hours: "",
        latitude: "",
        longitude: "",
        address: "",
        location_id: "",

    });

    useEffect(() => {
        const userDetails = sessionStorage.getItem('userDetails');
        if (!userDetails) {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetCustomers`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setCustomersData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setCustomersData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const customersOptions =  customersData && customersData.map((item) => ({
        value: item.userid,
        label: item.company,
    }));

    const handleCustomerSelectChange = (seelctedValue) => {
        setSelectedCustomerId(seelctedValue);
        setFormData(prevData => ({
            ...prevData,
            clientid: seelctedValue
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
                const response = await fetch(`${API_BASE_CRM_URL}GetProjectDetails?ProId=${id}`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
        
                if (data.Status) {
                    setProjectData(data.ResultData[0]);
                    setFormData({
                        name: data.ResultData[0].name || '',
                        description: data.ResultData[0].description || '',
                        status: data.ResultData[0].status || '',
                        clientid: data.ResultData[0].clientid || '',
                        billing_type: data.ResultData[0].billing_type || '',
                        start_date: formatDate(data.ResultData[0].start_date) || '',
                        deadline: formatDate(data.ResultData[0].deadline) || '',
                        project_created: formatDate(data.ResultData[0].project_created) || '',
                        date_finished: formatDate(data.ResultData[0].date_finished) || '',
                        progress: data.ResultData[0].progress || '',
                        project_cost: data.ResultData[0].project_cost || '',
                        estimated_hours: data.ResultData[0].estimated_hours || '',
                        latitude: data.ResultData[0].latitude || '',
                        longitude: data.ResultData[0].longitude || '',
                        address: data.ResultData[0].address || '',
                        location_id: data.ResultData[0].locationid || '',
                    });

                    setSelectedCustomerId(data.ResultData[0].clientid || '');

                } else {
                    console.error("Fetched data is not an array:", data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        formData.id = id;
        formData.clientid = selectedCustomerId;
        console.log(formData,'data sending to api');

        try {
            const response = await fetch(`${API_BASE_CRM_URL}UpdateProjectWithLocation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Id: id,
                    ...formData
                }),
            });

            const result = await response.json();

            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Project Updated Successfully.`,
                    icon: "success",
                     customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        navigate('/projects');
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to Request Submit.",
                    icon: "error",
                     customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error updating project.');
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (isoString) => {
        if (!isoString) return '';
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    

    return (
        <Base>
            <div className="page-heade mb-3">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="page-title">
                            <h4>Project Details</h4>
                            <p className='fw-semibold'>View and edit details</p>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-md-end justify-content-start">
                        <Link to='/projects'>
                            <button className="btn btn-primary" style={{ borderRadius: '10px' }}>
                                <i className="fa-solid fa-arrow-left me-2"></i> Go to List
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
    
            <div className="col-xxl-12 col-xl-12">
                <div className="card">
                    <div className="card-body" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        <form onSubmit={handleSubmit}>
                            <div className='row m-3'>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Name</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='name'
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Customer</label>
                                    <div className='d-flex'>
                                        <Select
                                            showSearch
                                            placeholder="Choose customer"
                                            value={formData.clientid ? formData.clientid : null}
                                            onChange={handleCustomerSelectChange}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={customersOptions}
                                            style={{ flexGrow: 1, height: '2.4rem' }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Status</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='status'
                                        value={formData.status}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Billing Type</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='billing_type'
                                        value={formData.billing_type}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Start Date</label>
                                    <input
                                        className='form-control'
                                        type='date'
                                        name='start_date'
                                        value={formData.start_date}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Deadline</label>
                                    <input
                                        className='form-control'
                                        type='date'
                                        name='deadline'
                                        value={formData.deadline}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Created Date</label>
                                    <input
                                        className='form-control'
                                        type='date'
                                        name='project_created'
                                        value={formData.project_created}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Finished Date</label>
                                    <input
                                        className='form-control'
                                        type='date'
                                        name='date_finished'
                                        value={formData.date_finished}
                                        onChange={handleChange}
                                    />
                                </div>
                                {/* <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Progress</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='progress'
                                        value={formData.progress}
                                        onChange={handleChange}
                                    />
                                </div> */}
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Cost of Project</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='project_cost'
                                        value={formData.project_cost}
                                        onChange={handleChange}
                                    />
                                </div>
                                {/* <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Estimated Hours</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='estimated_hours'
                                        value={formData.estimated_hours}
                                        onChange={handleChange}
                                    />
                                </div> */}
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Latitude</label>
                                    <input
                                        className='form-control'
                                        type='number'
                                        name='latitude'
                                        placeholder='Enter latitude'
                                        value={formData.latitude}
                                        onChange={handleChange}
                                         onWheel={(e) => e.target.blur()}
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Longitude</label>
                                    <input
                                        className='form-control'
                                        type='number'
                                        name='longitude'
                                        value={formData.longitude}
                                        placeholder='Enter longitude'
                                        onChange={handleChange}
                                         onWheel={(e) => e.target.blur()}
                                    />
                                </div>
                                <div className='col-md-4 col-lg-4 mb-2'>
                                    <label className='form-label'>Address</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='address'
                                        placeholder='Enter project address'
                                        value={formData.address}
                                        onChange={handleChange}
                                    />
                                </div>
                                
                            </div>
                            <div className='row m-3 col-2 m-auto'>
                                <button type="submit" className="btn btn-primary">
                                    {loading ? 'Submitting..' : 'Update Project'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Base>
    );
}
