import React, { useState, useEffect } from 'react';
import Base from '../../Config/Base';
import { Link, useParams } from 'react-router-dom';
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import '../../Config/swal.css';
import { Select } from 'antd';



export default function ViewExpense() {

    const { ExpenseID } = useParams();
    const [loading, setLoading] = useState(false);
    const [expenseData, setExpenseData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
                const response = await fetch(`${API_BASE_CRM_URL}GetExpenseDetails?ExpenseId=${ExpenseID}`);
                    if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();   

                if (data.Status) {
                    setExpenseData(data.ResultData[0]);

                } else {
                    console.error("Fetched data is not an array:", data);
                    setExpenseData([]);
                }
            } catch (error) {
                    console.error("Error fetching datasssss:", error);
            } finally {
                    setLoading(false);
            }
        };
    
        fetchData();
    }, [ExpenseID]);
    
    const formatDate = (isoString) => {
        if (!isoString) return '';
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <Base>
            <div className="page-heade mb-3">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="page-title">
                            <h4>View Expense</h4>
                            {/* <p className='fw-semibold'>Fill out the form to edit an expense</p> */}
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-md-end justify-content-start">
                        <Link to='/expenses'>
                            <button className="btn btn-primary" style={{ borderRadius: '10px' }}>
                                <i className="fa-solid fa-arrow-left me-2"></i> Go to List
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="col-xxl-12 col-xl-12" >
                <div className="card">
                    <div className="card-body" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        <form>
                            <ul className="nav nav-tabs tab-style-2 nav-justified mb-3 d-sm-flex d-block" id="myTab1"
                                role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="order-tab" data-bs-toggle="tab"
                                        data-bs-target="#order-tab-pane" type="button" role="tab" aria-controls="order-tab-pane"
                                        aria-selected="true">
                                        <i className="fa-regular fa-address-book"></i>Info
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="confirmed-tab" data-bs-toggle="tab"
                                        data-bs-target="#confirm-tab-pane" type="button" role="tab" aria-controls="confirm-tab-pane"
                                        aria-selected="false">
                                        <i className="fa-solid fa-map-location-dot"></i>Advanced
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active text-muted" id="order-tab-pane" role="tabpanel"
                                    aria-labelledby="order-tab" tabIndex="0">
                                    <div className='row m-3'>
                                        <div className='col-md-4 col-lg-4 mb-2'>
                                            <label className='form-label'>Name</label>
                                            <input 
                                                type='text'
                                                className='form-control' 
                                                name='expense_name'
                                                value={expenseData?.expense_name}
                                                placeholder='Enter expense name'
                                                readOnly
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Date</label>
                                            <input 
                                                className='form-control' 
                                                type='date'
                                                value={formatDate(expenseData?.date)}
                                                readOnly
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Amount</label>
                                            <input 
                                                className='form-control' 
                                                type='number'
                                                name='amount' 
                                                value={expenseData?.amount}
                                                placeholder='Enter amount'
                                                readOnly
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4 d-flex flex-column'>
                                            <label className='form-label'>Category</label>
                                            <input 
                                                className='form-control' 
                                                type='number'
                                                name='amount' 
                                                value={expenseData?.category_name}
                                                placeholder='Enter amount'
                                                readOnly
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4 d-flex flex-column'>
                                            <label className='form-label'>Customer</label>
                                            <input 
                                                className='form-control' 
                                                value={expenseData?.cus_name}
                                                placeholder='Enter amount'
                                                readOnly
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4 d-flex flex-column'>
                                            <label className='form-label'>Project</label>
                                            <input 
                                                className='form-control' 
                                                value={expenseData?.proj_name}
                                                placeholder='Enter amount'
                                                readOnly
                                            />
                                        </div>
                                        <div className='col-12 mt-2'>
                                            <label className='form-label'>Description</label>
                                            <textarea
                                                className='form-control' 
                                                name='note' 
                                                value={expenseData?.note}
                                                placeholder='Enter notes...'
                                                readOnly
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade text-muted" id="confirm-tab-pane" role="tabpanel"
                                    aria-labelledby="confirmed-tab" tabIndex="0">
                                    <div className='row m-3'>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Currency</label>
                                            <input 
                                                className='form-control'
                                                value="INR Rs"
                                                readOnly
                                            />
                                        </div>
                                       
                                        <div className='col-md-4 col-lg-4 d-flex flex-column'>
                                            <label className='form-label'>Payment Mode</label>
                                            <input 
                                                className='form-control' 
                                                type='number'
                                                name='amount' 
                                                value={expenseData?.paymentmode}
                                                placeholder='Enter amount'
                                                readOnly
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Reference no</label>
                                            <input 
                                                className='form-control'
                                                type='text'
                                                placeholder='Enter reference number'
                                                name='reference_no' 
                                                value={expenseData?.reference_no}
                                                readOnly
                                            />
                                        </div>
                                        <div className='col-md-4 col-lg-4'>
                                            <label className='form-label'>Supplier</label>
                                            <input 
                                                className='form-control' 
                                                type='text'
                                                name='supplier' 
                                                value={expenseData?.supplier}
                                                placeholder='Enter supplier name'
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mt-3 d-flex m-auto">{loading ? 'Submitting...' : 'Submit'}</button>
                        </form>
                    </div>
                </div>
            </div>
        </Base>
    );
}
