import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from "antd";
import '../../Config/swal.css';


export default function FollowUp({ cusOBJ }) {

  const [userDetails, SetUserDetails] = useState([]);

  useEffect(() => {
      const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
      SetUserDetails(userDetailsSession);
  }, []);

  const [loading, setLoading] = useState(false);
  const [staffData, setStaffData] = useState("");
  const [reminderChecked, setReminderChecked] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [remindTime, setRemindTime] = useState("");
  const [selectedStaffId, setSelectedStaffId] = useState(null);
  const [selectedFolTypeId, setSelectedFolTypeId] = useState(null);

  const [formData, setFormData] = useState({
    relId: cusOBJ?.userid || "",
    reminderType: "Customer",
    staffId: userDetails.staffid,
    description: "",
    FollowupType: "",
    Title: "",
    Date: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
            const response = await fetch(`${API_BASE_CRM_URL}GetStaff`);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
    
            if (data.Status) {
                setStaffData(data.ResultData);
            } else {
                console.error("Fetched data is not an array:", data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    fetchData();
}, []);

  const staffOptions =  staffData && staffData.map((item) => ({
    value: item.staffid,
    label: item.firstname + " " + item.lastname,
  }));

  const followupTypeOptions = [
    { value: 'Call', label: 'Call' },
    { value: 'Email', label: 'Email' },
    { value: 'Visit', label: 'Visit' },
    { value: 'Whatsapp', label: 'Whats app' },
];

  useEffect(() => {
    if (cusOBJ) {
      setFormData((prevData) => ({
        ...prevData,
        relId: cusOBJ.userid || prevData.relId,
      }));
    }
  }, [cusOBJ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handelStaffChange = (selectedOption) => {
    setSelectedStaffId(selectedOption);
  }

    const handelFollTypeChange = (selectedOption) => {
        setSelectedFolTypeId(selectedOption);
    }

  const handleCheckboxChange = (value) => {
    setSelectedCheckbox((prevValue) => (prevValue === value ? "" : value));
    setRemindTime("");
    setErrorMessage("");
  };


  const [remidnerFormData, setRemidnerFormData] = useState({
    hours: 0,
    days: 0,
    months: 0,
    reminderTime: 0,
    staffId: userDetails.staffid,
    relId: cusOBJ?.userid || "",
    reminderType: 'Lead',
    description: '',
})

  const handleRemindTimeChange = (event) => {
    const value = event.target.value;
    setRemindTime(value);

    if (!value) {
      setErrorMessage("");
    } else if (!/^[1-8]$/.test(value)) {
      setErrorMessage("Value must be between 1 and 8");
    } else {
      setErrorMessage("");
    }

    
    if (selectedCheckbox === "hours") {
      setRemidnerFormData((prevData) => ({
        ...prevData,
        hours: parseInt(value),
      }));
    } else if (selectedCheckbox === "days") {
      setRemidnerFormData((prevData) => ({
        ...prevData,
        days: parseInt(value),
      }));
    } else if (selectedCheckbox === "months") {
      setRemidnerFormData((prevData) => ({
        ...prevData,
        months: parseInt(value),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const followupData = {
        RelId: cusOBJ.userid,
        RelType: formData.reminderType,
        Date: formData.Date,
        CreatedBy: userDetails.staffid,
        Description: formData.description,
        FollowupType: selectedFolTypeId,
        Title: formData.Title,
        StaffId: selectedStaffId
    };

    console.log(followupData, "data sending to API follow-uppp");
    try {
      const response = await fetch(`${API_BASE_CRM_URL}AddFollowup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(followupData),
      });

      const result = await response.json();

      if (result.Status) {
        if (reminderChecked) {
          await addReminder();
        } else {
          setLoading(false);
          Swal.fire({
            title: `Follow-up added successfully.`,
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              window.location.reload();
            }
          });
        }
      } else {
        throw new Error("Failed to submit request.");
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
      });
    }
  };

    const addReminder = async () => {

        remidnerFormData.description = formData.description;
        remidnerFormData.reminderTime = remindTime;
        remidnerFormData.staffId = userDetails.staffid;
        remidnerFormData.relId =  cusOBJ?.userid || "";

        console.log(remidnerFormData, "reminder data sending");
        try {
        const response = await fetch(`${API_BASE_CRM_URL}AddReminder`, {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify(remidnerFormData),
        });

        const result = await response.json();
        if (result.Status) {
            setLoading(false);
            Swal.fire({
            title: `Reminder Set Successfully.`,
            icon: "success",
            customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
            }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
                window.location.reload();
            }
            });
        } else {
            throw new Error("Failed to set reminder.");
        }
        } catch (error) {
        setLoading(false);
        Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
        });
        }
    };

  return (

    <div 
        className="offcanvas offcanvas-end custom-offcanvas"
        tabIndex="-1"
        id="offcanvasFollowup"
        aria-labelledby="offcanvasRightLabel"
    >

        <style>
            {`
            .offcanvas.custom-offcanvas {
                width: 450px !important; /* Adjust this as needed */
            }

            @media (max-width: 576px) {
                .offcanvas.custom-offcanvas {
                width: 70% !important; /* Adjust the percentage as needed */
                }
            }
            `}
        </style>

        <div className="offcanvas-header">
            <h5>Add Followup</h5>
            <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
            ></button>
        </div>
        <div className="offcanvas-body ms-2">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6 col-lg-6">
                        <h6>{cusOBJ.company}</h6>
                    </div>
                    <div className="col-md-6 col-lg-6 mb-4">
                        <h6>{cusOBJ.city}</h6>
                    </div>

                    
                    <div className="mb-3 col-12">
                        <label className="form-label">Title<span className="text-danger fw-bold">*</span></label>
                        <input
                            type="text"
                            name="Title"
                            value={formData.Title}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter title"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Date<span className="text-danger fw-bold">*</span></label>
                        <input
                            type="datetime-local"
                            name="Date"
                            value={formData.Date}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Staff</label>
                        <div className='d-flex'>
                            <Select
                                showSearch
                                placeholder="Choose staff"
                                value={selectedStaffId || null}
                                onChange={handelStaffChange}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={staffOptions}
                                style={{ flexGrow: 1, height: '2.4rem' }}
                            />
                        </div>
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Type<span className="text-danger fw-bold">*</span></label>
                        <div className='d-flex'>
                            <Select
                                showSearch
                                placeholder="Choose staff"
                                value={selectedFolTypeId || null}
                                onChange={handelFollTypeChange}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={followupTypeOptions}
                                style={{ flexGrow: 1, height: '2.4rem' }}
                            />
                        </div>
                    </div>

                    <div className="form-check mb-3 col-12">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="reminderCheck"
                            checked={reminderChecked}
                            onChange={() => setReminderChecked(!reminderChecked)}
                        />
                        <label className="form-check-label" htmlFor="reminderCheck">
                            Set Reminder
                        </label>
                    </div>
                    {reminderChecked && (
                    <>
                        <div className="form-group col-md-2 me-3 d-flex align-items-center">
                            <label
                                className="form-label"
                                htmlFor="Hours"
                                style={{ marginRight: "4px" }}
                            >
                                Hours
                            </label>
                            <input
                                type="checkbox"
                                id="Hours"
                                checked={selectedCheckbox === "hours"}
                                onChange={() => handleCheckboxChange("hours")}
                            />
                        </div>
                            <div className="form-group col-md-2 me-3 d-flex align-items-center">
                            <label
                                className="form-label"
                                htmlFor="Days"
                                style={{ marginRight: "4px" }}
                            >
                                Days
                            </label>
                            <input
                                type="checkbox"
                                id="Days"
                                checked={selectedCheckbox === "days"}
                                onChange={() => handleCheckboxChange("days")}
                            />
                        </div>
                        <div className="form-group col-md-2 me-3 d-flex align-items-center">
                            <label
                                className="form-label"
                                htmlFor="Months"
                                style={{ marginRight: "4px" }}
                            >
                                Months
                            </label>
                            <input
                                type="checkbox"
                                id="Months"
                                className="me-5"
                                checked={selectedCheckbox === "months"}
                                onChange={() => handleCheckboxChange("months")}
                            />
                        </div>
                        <div className="form-group col-md-12 my-4">
                            <label className="form-label">Remind Time:</label>
                            <input
                                type="number"
                                className={`form-control ${
                                errorMessage ? "is-invalid" : ""
                                }`}
                                value={remindTime}
                                onChange={handleRemindTimeChange}
                                placeholder="Max value 1 to 8"
                            />
                            {errorMessage && (
                                <div className="invalid-feedback text-danger">
                                {errorMessage}
                                </div>
                            )}
                        </div>
                    </>
                    )}
                </div>
                <div className="mb-3 col-12">
                    <label className="form-label">Notes</label>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Enter Notes..."
                    />
                </div>
                <div className="m-auto col-2">
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? "Submitting..." : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    </div>
        
  );
}

FollowUp.propTypes = {
  cusOBJ: PropTypes.array.isRequired,
};
