
import React, { useState, useEffect } from "react";
import Base from "../../Config/Base";
import { Link } from "react-router-dom";
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Select } from 'antd';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import '../../Config/swal.css';


export default function AddChalan() {

    const [userDetails, SetUserDetails] = useState([]);
    const  navigate = useNavigate();

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const [selectedCustomerid, setSelectedCustomerid] = useState("");
    const [selectedprojectid, setSelectedProjectid] = useState("");
    const [customersData, setCustomersData] = useState([]);
    const [projectsData, setProjectsData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    
    const [siteLatitude, setSiteLatitude] = useState('');
    const [siteLongitude, setSiteLongitude] = useState('');
    const [calculatedDistance, setCalculatedDistance] = useState('');
    const [vehicleNo, setVehicleNo] = useState('');
    const [deliveryPerson, setDeliveryPerson] = useState('');
    const [description, setDescription] = useState('');
    const [chalanDate, setChalanDate] = useState('');
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetCustomers`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setCustomersData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setCustomersData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_CRM_URL}GetProducts`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                if (data.Status) {
                    setProductsData(data.ResultData);
                } else {
                    console.error("Fetched data is not an array:", data);
                    setProductsData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedCustomerid) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${API_BASE_CRM_URL}GetProjectsByCusId?CusID=${selectedCustomerid}`);
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    const data = await response.json();
                    if (data.Status) {
                        setProjectsData(data.ResultData);
                    } else {
                        console.error("Fetched data is not an array:", data);
                        setProjectsData([]);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
        }
    }, [selectedCustomerid]);

    const customerOptions =  customersData && customersData.map((item) => ({
        value: item.userid,
        label: item.company,
    }));

    const projectOptions =  projectsData && projectsData.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    const productOptions =  productsData && productsData.map((item) => ({
        value: item.id,
        label: item.description,
    }));

    const handleCustomerChange = (selectedone) => {
        setSelectedCustomerid(selectedone);
    };

    const handleProjectChange = (selectedone) => {
        setSelectedProjectid(selectedone);
    };

    const handleDateChange = (event) => {
        setChalanDate(event.target.value);
    };

    const addRow = (product) => {
        console.log(product, 'pppppppppppp')
        const newRow = { 
            commodity_name: product.label, 
            commodity_code: product.value || '',
            quantitities: '', 
            weight: '' 
        };
        setRows([...rows, newRow]);
    };

    const handleProductChange = (value, option) => {
        addRow(option); 
    };

    const handleInputChange = (index, field, value) => {
        const newRows = rows.map((row, rowIndex) => {
            if (rowIndex === index) {
                return { ...row, [field]: value };
            }
            return row;
        });
        setRows(newRows);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const chalanData = {
            rel_type: 'Customer',
            customer_code: selectedCustomerid,
            description: description,
            warehouse_id: 1,
            staff_id: userDetails.staffid,
            date_c: chalanDate,
            date_add: new Date().toISOString().split('T')[0],
            addedfrom: userDetails.staffid,
            project: selectedprojectid,
            note: description,
            vehicle_no: vehicleNo,
            driver_name: deliveryPerson,
            site_latitude: siteLatitude,
            site_longitude: siteLongitude,
            warehouse_address: 'Warehouse Address',
            weight: 1000,
            distance: calculatedDistance,
            site_address: 'Site Address',
            shipping_fee: 500,
            commodity_details: rows,
        };

        console.log(chalanData, 'data sending to api');
    
        try {
            const response = await fetch(`${API_BASE_CRM_URL}AddChallanWithDetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(chalanData),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log(result);
            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Purchase Order Added Successfully.`,
                    icon: "success",
                     customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        // window.location.reload();
                        navigate('/delivery-challan')
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                     customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };
    

    return (
        <Base>
            
            <div className="page-header">
                <div className="add-item d-flex">
                    <div className="page-title">
                        <h4>Add Delivery Chalan</h4>
                        <h6>Enter fields for add challan</h6>
                    </div>
                </div>
                <ul className="table-top-head">
                    <li>
                        <Link to='/delivery-challan' className="btn btn-added"><i className="fa-solid fa-arrow-left me-1"></i>Go to List</Link>
                    </li>
                </ul>
            </div>
            
            <div className="card table-list-card">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row m-2">
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Customer</label>
                                <Select
                                    showSearch
                                    placeholder="Choose Customer"
                                    value={selectedCustomerid || null}
                                    onChange={handleCustomerChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={customerOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div> 
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Project</label>
                                <Select
                                    showSearch
                                    placeholder="Choose project"
                                    value={selectedprojectid || null}
                                    onChange={handleProjectChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={projectOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Chalan Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={chalanDate}
                                    onChange={handleDateChange}
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Site Latitude</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={siteLatitude}
                                    onChange={(e) => setSiteLatitude(e.target.value)}
                                    placeholder="Enter site latitude"
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Site Longitude</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={siteLongitude}
                                    onChange={(e) => setSiteLongitude(e.target.value)}
                                    placeholder="Enter site longitude"
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Distance</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={calculatedDistance}
                                    placeholder="Site distance will display here"
                                    readOnly
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Vehicle No</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={vehicleNo}
                                    onChange={(e) => setVehicleNo(e.target.value)}
                                    placeholder="Enter vehicle no"
                                />
                            </div>
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Delivery Person</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={deliveryPerson}
                                    onChange={(e) => setDeliveryPerson(e.target.value)}
                                    placeholder="Enter driver name"
                                />
                            </div>
                            {/* <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Add Product</label>
                                <button className="btn btn-primary col-2" onClick={addRow}><i className="fa fa-plus"></i></button>
                            </div> */}
                            <div className="d-flex flex-column col-lg-4 col-md-4 mb-2">
                                <label className="form-label">Product</label>
                                <Select
                                    showSearch
                                    placeholder="Choose product"
                                    onChange={handleProductChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={productOptions}
                                    style={{ height: '2.4rem' }}
                                />
                            </div>
                            <div className="col-12 my-3">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                            <th>Weight</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map((row, index) => (
                                            <tr key={row.id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Product name"
                                                        value={row.commodity_name}
                                                        onChange={(e) => handleInputChange(index, 'commodity_name', e.target.value)}
                                                        readOnly
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Quantity"
                                                        value={row.quantitities}
                                                        onChange={(e) => handleInputChange(index, 'quantitities', e.target.value)}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Weight"
                                                        value={row.weight}
                                                        onChange={(e) => handleInputChange(index, 'weight', e.target.value)}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex flex-column col-lg-12 col-md-12">
                                <label className="form-label">Description</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Enter notes..."
                                />
                            </div>
                            <div className="d-flex flex-column col-2 m-auto mt-2">
                                <button className="btn btn-primary" type="submit">{loading ? 'Submitting...' : 'Submit'}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Base>
    )
}