import React, { useState, useEffect } from 'react';
import Base from '../../Config/Base';
import { Link } from 'react-router-dom';
import { API_BASE_CRM_URL } from "../../Config/Config";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import '../../Config/swal.css';

export default function AddCustomer() {

    const [userDetails, SetUserDetails] = useState([]);

    useEffect(() => {
        const userDetailsSession = JSON.parse(sessionStorage.getItem('userDetails'));
        SetUserDetails(userDetailsSession);
    }, []);

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        company: '',
        phonenumber: '',
        website: '',
        datecreated: '',
        default_language: '',
        city: '',
        state: '',
        zip: '',
        billing_street: '',
        billing_city: '',
        billing_state: '',
        billing_zip: '',
        shipping_street: '',
        shipping_city: '',
        shipping_state: '',
        shipping_zip: ''
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        // Create the data object to send as JSON
        const formDataToSend = {
            Company: formData.company,
            PhoneNo: formData.phonenumber,
            Website: formData.website,
            DefaultLanguage: formData.default_language,
            City: formData.city,
            State: formData.state,
            Zip: formData.zip,
            Country: 1,
            BillingStreet: formData.billing_street,
            BillingCity: formData.billing_city,
            BillingState: formData.billing_state,
            BillingZip: formData.billing_zip,
            ShippingStreet: formData.shipping_street,
            ShippingCity: formData.shipping_city,
            ShippingState: formData.shipping_state,
            ShippingZip: formData.shipping_zip,
            AddedFrom: userDetails.staffid,
            Lead: null,
            BillingCountry: null,
            ShippingCountry: null,
            Latitude: null,
            Longitude: null,
            DefaultCurrency: 1,
        };

        console.log(formDataToSend, 'data to api')
    
        try {
            const response = await fetch(`${API_BASE_CRM_URL}AddCustomer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formDataToSend) // Send the data as JSON
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const result = await response.json();
            if (result.Status) {
                setLoading(false);
                Swal.fire({
                    title: `Customer Added Successfully.`,
                    icon: "success",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        // window.location.reload();
                        navigate('/customers');
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Failed to submit request.",
                    icon: "error",
                    customClass: {
                        title: 'swal-title',
                        content: 'swal-text'
                    }
                });
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            Swal.fire({
                title: "Error",
                text: "An error occurred while submitting the request.",
                icon: "error",
            });
        }
    };
    

  return (
    <Base>
        <div className="page-heade mb-3">
            {/* <div className="container"> */}
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="page-title">
                            <h4>Add Customer</h4>
                            <p className='fw-semibold'>Fill out the form to add a new customer</p>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-md-end justify-content-start">
                        <Link to='/customers'>
                            <button className="btn btn-primary" style={{ borderRadius: '10px' }}>
                                <i className="fa-solid fa-arrow-left me-2"></i> Go to List
                            </button>
                        </Link>
                    </div>
                {/* </div> */}
            </div>
        </div>

        <div className="col-xxl-12 col-xl-12" >
            <div className="card">
                <div className="card-body" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                    <form onSubmit={handleSubmit}>
                        <ul className="nav nav-tabs tab-style-2 nav-justified mb-3 d-sm-flex d-block" id="myTab1"
                            role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="order-tab" data-bs-toggle="tab"
                                    data-bs-target="#order-tab-pane" type="button" role="tab" aria-controls="order-tab-pane"
                                    aria-selected="true">
                                    <i className="fa-regular fa-address-book"></i>Personal Info
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="confirmed-tab" data-bs-toggle="tab"
                                    data-bs-target="#confirm-tab-pane" type="button" role="tab" aria-controls="confirm-tab-pane"
                                    aria-selected="false">
                                    <i className="fa-solid fa-map-location-dot"></i>Billing
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="shipped-tab" data-bs-toggle="tab"
                                    data-bs-target="#shipped-tab-pane" type="button" role="tab" aria-controls="shipped-tab-pane"
                                    aria-selected="false">
                                    <i className="fa-solid fa-truck-fast"></i>Shipping
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active text-muted" id="order-tab-pane" role="tabpanel"
                                aria-labelledby="order-tab" tabIndex="0">
                                <div className='row m-3'>
                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>Organization Name</label>
                                        <input className='form-control' name='company' value={formData.company}
                                            placeholder='Enter organization name'
                                            required
                                            onChange={handleChange} />
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Phone Number</label>
                                        <input className='form-control' name='phonenumber' value={formData.phonenumber}
                                        required
                                        placeholder='Enter phone number'
                                            onChange={handleChange} />
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Website</label>
                                        <input className='form-control' name='website' value={formData.website}
                                        placeholder='Enter website'
                                            onChange={handleChange} />
                                    </div>
                                    {/* <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>Created On</label>
                                        <input className='form-control' name='datecreated' value={formData.datecreated}
                                            onChange={handleChange} />
                                    </div> */}
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Language</label>
                                        <input className='form-control' name='default_language'
                                        placeholder='Enter language'
                                            value={formData.default_language} onChange={handleChange} />
                                    </div>

                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>City</label>
                                        <input className='form-control' name='city' value={formData.city}
                                        placeholder='Enter city'
                                            onChange={handleChange} />
                                    </div>
                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>State</label>
                                        <input className='form-control' name='state' value={formData.state}
                                        placeholder='Enter state'
                                            onChange={handleChange} />
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Zipcode</label>
                                        <input className='form-control' name='zip' value={formData.zip}
                                        placeholder='Enter zipcode'
                                            onChange={handleChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade text-muted" id="confirm-tab-pane" role="tabpanel"
                                aria-labelledby="confirmed-tab" tabIndex="0">
                                <div className='row m-3'>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Billing Street</label>
                                        <input className='form-control' name='billing_street' value={formData.billing_street}
                                        placeholder='Enter street'
                                            onChange={handleChange} />
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Billing City</label>
                                        <input className='form-control' name='billing_city' value={formData.billing_city}
                                        placeholder='Enter city'
                                            onChange={handleChange} />
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Billing State</label>
                                        <input className='form-control' name='billing_state' value={formData.billing_state}
                                        placeholder='Enter city'
                                            onChange={handleChange} />
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Billing Zipcode</label>
                                        <input className='form-control' name='billing_zip' value={formData.billing_zip}
                                        placeholder='Enter zipcode'
                                            onChange={handleChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade text-muted" id="shipped-tab-pane" role="tabpanel"
                                aria-labelledby="shipped-tab" tabIndex="0">
                                <div className='row m-3'>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Shipping Street</label>
                                        <input className='form-control' name='shipping_street' value={formData.shipping_street}
                                        placeholder='Enter street'
                                            onChange={handleChange} />
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Shipping City</label>
                                        <input className='form-control' name='shipping_city' value={formData.shipping_city}
                                        placeholder='Enter city'
                                            onChange={handleChange} />
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Shipping State</label>
                                        <input className='form-control' name='shipping_state' value={formData.shipping_state}
                                        placeholder='Enter state'
                                            onChange={handleChange} />
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Shipping Zipcode</label>
                                        <input className='form-control' name='shipping_zip' value={formData.shipping_zip}
                                        placeholder='Enter zipcode'
                                            onChange={handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary mt-3">{loading ? 'Submitting...' : 'Submit'}</button>
                    </form>
                </div>
            </div>
        </div>
    </Base>
  );
}
