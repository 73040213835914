import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Base from '../../Config/Base';
import { API_BASE_CRM_URL } from "../../Config/Config";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";

export default function ViewLead() {

    const navigate = useNavigate();
    const { id } = useParams(); 

    const [leadsData, setLeadsData] = useState([]);
    const [leadContacts, setLeadContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [whLoading, setwhLoading] = useState(false);
    const [whMessage, setWhMessage] = useState('');

    useEffect(() => {
        const userDetails = sessionStorage.getItem('userDetails');
        if (!userDetails) {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        if (id) {
            const fetchContacts = async () => {
                setLoading(true);
                try {
                    const response = await fetch(`${API_BASE_CRM_URL}GetLeadDetails?LeadId=${id}`);
            
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
            
                    const data = await response.json();
                    console.log(data, 'data view lead detailssss');
                    
                    // Ensure data is an array
                    if (data.Status) {
                        setLeadsData(data.ResultData[0]);
                    } else {
                        setLeadsData([]);
                        console.error("Unexpected response format:", data);
                    }
                    } catch (error) {
                    console.error("Error fetching data:", error);
                    } finally {
                    setLoading(false);
                    }
                };
            
                fetchContacts();
            }
    }, [id]);

    useEffect(() => {
        if (leadsData && leadsData.id) {
            const fetchContacts = async () => {
                setLoading(true);
                try {
                    const response = await fetch(`${API_BASE_CRM_URL}GetLeadContacts?LeadId=${leadsData.id}`);
            
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
            
                    const data = await response.json();
                    console.log(data, 'data view lead contacts');
                    
                    // Ensure data is an array
                    if (data.Status) {
                        setLeadContacts(data.ResultData);
                    } else {
                        setLeadContacts([]);
                        console.error("Unexpected response format:", data);
                    }
                    } catch (error) {
                    console.error("Error fetching data:", error);
                    } finally {
                    setLoading(false);
                    }
                };
            
                fetchContacts();
            }
    }, [leadsData]);

    const handelWhatsapp = async (e) => {
        e.preventDefault();

        setwhLoading(true);

        const whatsappData = {
            "superid":10001,
            "tomobile":9866954660,
            "message":`
                Organization: ${leadsData.company}
                City: ${leadsData.city}
                Name: ${leadsData.name}
                Mobile: ${leadsData.phonenumber}
                Address: ${leadsData.address}
            `
        };

        try {
            const response = await fetch(`https://notifyservisesrc.azurewebsites.net/notify/sendwhatsapp`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(whatsappData)
            });

            const data = await response.json();
            if (data.status) {
                setWhMessage('Message Sent Successfully!');
            }
            else {
                setWhMessage('Message Sending Failed!');
            }
            console.log(data.status);
        } catch (error) {
            console.error("Error adding location:", error);
        }
    };


    return (
        <Base>
           <div className="page-heade mb-3">
                {/* <div className="container"> */}
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="page-title">
                                <h4>Lead Details</h4>
                                <p className='fw-semibold'>View details</p>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-md-end justify-content-start">
                            <div className='d-flex flex-column'>
                                <button className='btn' onClick={handelWhatsapp}><i className="fa-brands fa-whatsapp fs-4 text-success"></i></button>

                            </div>
                            <button className='btn me-2'><i className="fa-regular fa-envelope fs-4 text-danger"></i></button>
                            <Link to='/leads'>
                                <button className="btn btn-primary" style={{ borderRadius: '10px' }}>
                                    <i className="fa-solid fa-arrow-left me-2"></i> Go to List
                                </button>
                            </Link>
                        </div>
                                <h5 style={{ zIndex: '2000', position: 'relative' }} className='text-end'>Message Sent Ssuccessfuly!</h5>
                    </div>
                {/* </div> */}
            </div>
        
            <div className="col-xxl-12 col-xl-12">
                <div className="card">
                    <div className="card-body" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        <ul className="nav nav-tabs tab-style-2 nav-justified mb-3 d-sm-flex d-block" id="myTab1"
                            role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="order-tab" data-bs-toggle="tab"
                                    data-bs-target="#order-tab-pane" type="button" role="tab" aria-controls="order-tab-pane"
                                    aria-selected="true">
                                    <i className="fa-solid fa-sitemap"></i>Info
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="confirmed-tab" data-bs-toggle="tab"
                                    data-bs-target="#confirm-tab-pane" type="button" role="tab" aria-controls="confirm-tab-pane"
                                    aria-selected="false">
                                    <i className="fa-regular fa-address-book"></i>Contacts
                                </button>
                            </li>
                            
                        </ul>
                        {loading ? 
                        
                        <div className="text-center">
                            <div className="containers d-flex justify-content-center m-auto">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                            </div>
                        </div>
                    
                    :
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active text-muted" id="order-tab-pane" role="tabpanel"
                                aria-labelledby="order-tab" tabIndex="0">
                                <div className='row m-3'>
                                    <h5 className='mb-2'>Organization Information:</h5>
                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>Name</label>
                                        <input type='text' className='form-control' name='company' value={leadsData?.company || ''}/>
                                    </div>
                                    <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>Source</label>
                                        <input type='text' className='form-control' value={leadsData?.SourceName || ''} />
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Status</label>
                                        <input type='text' className='form-control' value={leadsData?.StatusName || ''}
                                                />
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>City</label>
                                        <input type='text' className='form-control' value={leadsData?.city || ''} 
                                                />
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Zipcode</label>
                                        <input type='text' className='form-control' value={leadsData?.zip || ''}
                                                />
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Address</label>
                                        <input type='text' className='form-control' value={leadsData?.address || ''}
                                                />
                                    </div>
                                    <h5 className='mb-2 mt-3'>Contact Person:</h5>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Name</label>
                                        <input type='text' className='form-control' value={leadsData?.name || ''} />
                                    </div>
                                    <div className='col-md-4 col-lg-4'>
                                        <label className='form-label'>Title</label>
                                        <input type='text' className='form-control' value={leadsData?.title || ''}
                                                />
                                    </div>
                                    <div className='col-md-4 col-lg-4 mt-1'>
                                        <label className='form-label'>Mobile</label>
                                        <input type='text' className='form-control' value={leadsData?.phonenumber || ''} />
                                    </div>
                                    <div className='col-md-4 col-lg-4 mt-1'>
                                        <label className='form-label'>Email</label>
                                        <input type='text' className='form-control' value={leadsData?.email || ''} />
                                    </div>
                                    <div className='col-md-4 col-lg-4 mt-1'>
                                        <label className='form-label'>Website</label>
                                        <input type='text' className='form-control' value={leadsData?.website || ''} 
                                                />
                                    </div>
                                    {/* <div className='col-md-4 col-lg-4 mb-2'>
                                        <label className='form-label'>State</label>
                                        <input type='text' className='form-control' value={leadsData?.state || ''} 
                                                />
                                    </div> */}
                                    
                                </div>
                            </div>
                            <div className="tab-pane fade text-muted" id="confirm-tab-pane" role="tabpanel"
                                aria-labelledby="confirmed-tab" tabIndex="0">
                                <div className='row m-3'>
                                    <div className='col-12 table-responsive' style={{ maxHeight: '25rem', overflowY: 'auto' }}>
                                        <table className='table table-hover'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Mobile</th>
                                                    <th>Email</th>
                                                    <th>Designation</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {leadContacts.length > 0 ? (
                                                    leadContacts.map((item, index) => (
                                                        <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.FullName}</td>
                                                        <td>{item.Mobile}</td>
                                                        <td>{item.Email}</td>
                                                        <td>{item.Designation}</td>
                                                        </tr>
                                                    ))
                                                    ) : (
                                                    <tr>
                                                        <td colSpan="12" className='text-center'>No contacts found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>

        </Base>
    );
}
